.view-confirmed-booking {
    display: flex;
    flex-direction: column;
    width: 80vw;
    margin-bottom: 20px;
}

.view-confirmed-booking > h3 {
    margin-bottom: 40px;
}

.booking-details-div {
    display: flex;
}

.vb-left-col {
    flex: 0.4;
    display: flex;
    align-items: center;
    padding: 20px;
    justify-content: center;
}

.vb-right-col {
    flex: 0.6;
    /* border: 2px solid gray; */
    padding: 10px;
}


.booking-details-div img {
    height: auto;
    width: 50%;
}

.revenue-div {
    display: flex;
    justify-content: space-between;
    width: 50%;
}

.revenue-div p {
    margin-bottom: 5px;
}

.net-revenue {
    font-weight: bold;
    text-decoration: underline;

}

.bold-text {
    font-weight: 600;
}

.vb-button-row {
    margin-top: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.button-left {
    flex: 0.2;
}

.vb-button-row button {
    width: 80px;
    color: white;
    padding: 8px;
    border-radius: 3px;
    font-size: 14px;
    font-weight: bold;
}

.vb-button-row button:hover {
    cursor: pointer;
}

.vb-view-button {
    background-color: black;
    border: 1px solid black;
}
.vb-view-button:hover {
    background-color: white;
    color: black;
}

.vb-deny-button {
    background-color: #D94A4A;
    border: 1px solid #D94A4A;
}

.vb-deny-button:hover {
    background-color: white;
    color: #D94A4A;
}

.vb-confirm-button {
    background-color: #023e73;
    border: 1px solid #023e73;
    margin-left: 40px;
}

.vb-confirm-button:hover {
    background-color: white;
    color: #023e73;
}

.view-confirmed-booking .bc-button-row {
    justify-content: flex-end !important;
}

.view-confirmed-booking .bc-button-row .button {
    margin-right: 20px !important;
}

.view-confirmed-booking .bc-confirm-button {
    margin-left: 0px !important;
}