.signup-confirmation-modal {
    z-index: 999;
    background-color: rgba(0,0,0,0.7); /* Black w/ opacity */
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.signup-modal-content {
    width: max-content;
    height: 45vh;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    color: black;
    background-color: white; /* Black w/ opacity */
}

.signup-modal-content h1 {
    text-transform: uppercase;
}

.proceed-button-row button {
    font-weight: bold;
    color: rgb(207, 204, 204);
    border: none;
    background-color: black;
    border-radius: 2px;
    font-size: 20px;
    padding: 7px 30px 7px 30px;
    border: 1px solid black;
    margin-bottom: 15px;
    margin-top: 20px;
}

.proceed-button-row button:hover {
    cursor: pointer;
    color: black;
    background-color: transparent;
}

.proceed-button-row button:active {
    background-color: white;
}