.profile-creation {
    display: flex;
    flex-direction: column;
    padding-left: 40px;
    padding-top: 30px;
    height: max-content;
}

.pc-header-container {
    display: flex;
    /* border: 1px solid #D94A4A; */
    border-radius: 5px;
    align-items: center;
    width: max-content;
    height: 8vh;
    padding: 10px;
}

.pc-header-container h1 {
    text-decoration: none;
    text-transform: uppercase;
}

.account-icon {
    transform: scale(2);
    margin-right: 20px;
    margin-left: 10px;
    margin-top: 0;
}

.profile-creation .error-message-row {
    margin-top: 20px;
}

.pc-personal-details {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 30px;

}

.pc-personal-details h3 {
    text-transform: uppercase;
    text-decoration: underline;
    margin-bottom: 20px;
}

.pc-bank-details {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 30px;

}

.pc-bank-details h3 {
    text-transform: uppercase;
    text-decoration: underline;
    margin-bottom: 20px;
}

.pc-input-row {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
}

.pc-input-row-single {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
}

.pc-input-group {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* width: 40%; */
    flex: 0.5;
    /* padding-right: 50px; */
}

.pc-input-group-single {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* width: 40%; */
    flex: 0.5;
    padding-right: 50px;
}

.pc-input-group p {
    flex: 0.3;
    text-transform: uppercase;
}

.pc-input-group input {
    flex: 0.4;
    padding: 4px;
    /* border: none !important; */
    border-color: transparent;
    box-shadow: 3px 3px 10px -1px gray !important;
    /* width: 20vw; */
}

.pc-personal-details span {
    margin-bottom: 15px;
}

.pc-personal-details textarea {
    width: 50%;
}

.profile-pic {
    /* height: 300%; */
        border: 1px solid black;
    transform: scale(5);
    padding: 5px;
}

.pp-container {
    display: flex;
    align-items: center;
    border: 1px solid black;
    width: auto;
    height: 200px;
    width: 200px;
    justify-content: center;
}

.drag-drop {
    margin-bottom: 7px;
    font-size: 90%;
    opacity: 0.7;
}

.pc-button-row {
    margin-top: 30px;
    margin-bottom: 30px;
}

.pc-button-row button {
    font-weight: bold;
    color: white;
    border: none;
    background-color: #D94A4A;
    border-radius: 2px;
    font-size: 20px;
    padding: 7px 30px 7px 30px;
    border: 1px solid #D94A4A;
    margin-bottom: 40px;
    margin-top: 20px;
}

.pc-button-row button:hover {
    cursor: pointer;
    color: #D94A4A;
    background-color: white;
}

.pc-button-row button:active {
    background-color: black;
}

.red-border {
    border-color: #D94A4A;;
}

._loading_overlay_wrapper {
    z-index: 1;
}

.profile-creation .dz-upload-container {
    width: 40%;
    padding: 50px 20px;
    text-align: center;
}

.pp-dropzone {
    flex: 0.4;
}

.pp-dzu {
    margin: 0px !important;
    padding: 0px !important;
    width: 100% !important;
    padding: 0px 4px !important;
    height: 40px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media all and (min-width: 1024px) and (max-width: 1280px) { }

@media all and (min-width: 768px) and (max-width: 1024px) { }

@media all and (min-width: 480px) and (max-width: 768px) {
   .profile-creation {
        padding: 20px !important;
    }

 }

@media all and (max-width: 480px) {

    .profile-creation {
        padding: 20px !important;
    }

 }