

.settings-item {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.settings-item p {
    flex: 0.8;
    font-size: 16px;
}

.settings-buttons {
    flex: 0.2;
}

.settings-button {
    padding: 12px;
    background-color: silver;
    border: none;
    font-weight: bold;
}

.settings-button:hover {
    cursor: pointer;
}

.active-settings-button {
    background-color: black !important;
    color: white;
}

.left-button {
    border-radius: 5px 0px 0px 5px;
}

.right-button {
    border-radius: 0px 5px 5px 0px;
}

