.pay-booking-modal {
    z-index: 999;
    background-color: rgba(0,0,0,0.7); /* Black w/ opacity */
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.general-button {
    background-color: black;
    border-color: black;
    cursor: pointer;
    transition: 200ms ease-in-out;
}

.pay-booking-modal-content {
    border: 1px solid grey;
    border-radius: 3px;
    width: max-content;
    height: max-content;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    color: black;
    background-color: white; /* Black w/ opacity */
}

.credits-info-container {
    width: 50%;
}

.css-v3tg4z {
    background: pink !important;
}

.pay-booking-modal-content .booking-request-row .fat-pee {
    width: 5vw;
}

.yc-field-group--card-number .yc-card-input-wrapper {
    border: 1px solid #dddddd;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 5px;
    /* width: 100%; */
    font-weight: bold;
}

.yc-expiry-cvv-holder .yc-card-input-wrapper {
    border: 1px solid #dddddd;
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 10px;
    /* width: 50%; */
}