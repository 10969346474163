.instructor-creation {
    display: flex;
    flex-direction: column;
    padding-left: 40px;
    padding-top: 30px;
    height: max-content;
}

.instructor-creation h3 {
    text-transform: uppercase;
    text-decoration: underline;
    margin-bottom: 5px;
}

.payment-methods-group {
    display: flex;
    flex-direction: column;
}

.payment-methods-item {
    display: flex;
    align-items: center;
}

.payment-methods-item p {
    margin: 0px 50px 0px 10px;
}

.instructor-creation .location-input-row {
    display: flex;
    width: 50%;
}

.ic-location-details label {
    margin-bottom: 3px;
}


.ic-location-details .searchWrapper {
    height: 50px !important;
}

.ic-button-row {
    margin-top: 40px;
    display: flex;
    width: 90%;
    margin-bottom: 40px;
    justify-content: flex-end;
}

.ic-button-row button {
    background-color: #023e73;
    padding: 10px 50px;
    font-size: 20px;
    color: white;
    border-radius: 5px;
    border: 1px solid #023e73;
    transition: 200ms ease-in;
}

.ic-button-row button:hover {
    cursor: pointer;
    background-color: transparent;
    color: #023e73;
}

.ic-skills-rates {
    margin-top: 40px;
}

.skills-rates-container {
    width: 100%;
    display: flex;
}

.s-r-left {
    flex: 0.5;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    width: 100%;
}

.s-r-right {
    flex: 0.5;
}

.skills-row {
    display: flex;
    width: 100%;
    margin-top: 20px;

}

.skills-block {
    border: 1px solid lightgray;
    display: flex;
    flex-direction: column;
    height: max-content;
    width: 17vw;
    height: 15vh;
    border-radius: 8px;
    margin-right: 20px;
}

.ic-skills-rates .MuiSvgIcon-root {
    fill: lightgray !important;
}

.skills-block-top {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.skills-block-bottom {
    display: flex;
    align-items: center;
    justify-content: flex-start;

}

.skills-block:hover {
    cursor: pointer;
    background-color: #dddddd33;
}

.skills-block-active {
    background-color: black;
    color: white;
    border-color: black;
}

.skills-block-active:hover {
    background-color: black;
}

.skills-block-active .MuiSvgIcon-root {
    fill: white !important;
}

.skills-block-bottom p {
    width: 75%;
    text-transform: uppercase;
    padding: 15px 20px;
    font-weight: bold;
    font-size: 14px;
}

.specialties-container {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px 0px 0px;
    width: 75%;
}

.specialties-left {
    flex: 0.5;
}

.specialties-right {
    flex: 0.5;
}

.sign-back-button {
    background-color: #D94A4A !important;
    border-color: #D94A4A !important;
}

.sign-back-button:hover {
    color: #D94A4A !important;
    background-color: transparent !important;
}

.button-row-step-two {
    justify-content: space-between;
}

.pc-input-group p {
    white-space: nowrap;

}

.pc-input-group .multiselect-container {
    width: 41% !important;
    margin-left: 83px;
}

.pc-input-group .select-search {
    /* width: 196px; */
    flex: 0.4;
}

.ic-location-details p {
    opacity: 0.5;
    margin-bottom: 20px;

}

.gm-err-autocomplete {
    width: 75%;
}

.business-location-row {
    margin-top: 40px;
}

.travel-distance-row {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
}

.autocomplete-input {
    width: 300px;
}

.ic-location-input-group {
    display: flex;
    align-items: center;
}

.use-location-group {
    margin-left: 50px;
    display: flex;
    align-items: center;
    text-align: center !important;
}

.room-icon {
    margin-right: 10px;
    opacity: 0.5;
}

.room-icon:hover {
    cursor: pointer;
}

.use-location-group p {
    margin: auto;

}


.range-group {
    display: flex;
    align-items: center;
}

.range-group input {
    color: black;
    /* background-color: black; */
}
.travel-distance-row p {
    opacity: 1;
}

.red-border {
    border: 1px solid #D94A4A !important;
}

.instructor-creation .ic-location-input-group .css-yk16xz-control {
    border-color: transparent;
}

.range-group p {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-top: 18px;
}

input[type=range] {
    height: 25px;
    -webkit-appearance: none;
    margin: 10px 0;
    width: 75%;
    overflow: hidden;
    /* background-color: black; */
  }
  input[type=range]:focus {
    outline: none;
  }
  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: #dddddd;
    border-radius: 1px;
    border: 0px solid #000000;
  }
  input[type=range]::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid #000000;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: #000000;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -7px;
  }
  input[type=range]:focus::-webkit-slider-runnable-track {
    /* background: #000000; */
  }
  input[type=range]::-moz-range-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: #dddddd;
    border-radius: 1px;
    border: 0px solid #000000;
  }
  input[type=range]::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid #000000;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: #000000;
    cursor: pointer;
  }
  input[type=range]::-ms-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  input[type=range]::-ms-fill-lower {
    background: #dddddd;
    border: 0px solid #000000;
    border-radius: 2px;
    box-shadow: 0px 0px 0px #000000;
  }
  input[type=range]::-ms-fill-upper {
    background: #dddddd;
    border: 0px solid #000000;
    border-radius: 2px;
    box-shadow: 0px 0px 0px #000000;
  }
  input[type=range]::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid #000000;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: #000000;
    cursor: pointer;
  }
  input[type=range]:focus::-ms-fill-lower {
    background: #dddddd;
  }
  input[type=range]:focus::-ms-fill-upper {
    background: #dddddd;
  }


  .css-187mznn-MuiSlider-root {
      width: 35% !important;
      margin-left: 5vw;
      margin-right: 5vw;
  }

  .specialities-right {
      padding-right: 20px;
  }

  .add-skills-container {
    margin-top: 20px;
    display: flex;
  }

  .MuiSlider-thumb {
      margin-top: 0px;
  }

  .add-skills-container button {
      margin-left: 20px;
      color: white;
      background-color: black;
      box-shadow: 0 3px 2px -2px gray;
      border: 1px solid black;
      border-radius: 4px;
      padding: 3px 10px;
      transition: 200ms ease-in transform;
  }

  .add-skills-container button:hover {
    transform: scale(1.03);
    cursor: pointer;
  }

  .add-skills-container button:active {
      background-color: transparent;
      color: black
  }

  .add-skills-container input {
      border: none;
      border-bottom: 1px solid grey;
  }

  .no-specialties {
      opacity: 0.5;
      margin-top: 30px;
      display: flex;
      align-items: center;
      /* justify-content: center; */
  }

  .selected-specs {
      border: 2px solid grey;
      border-radius: 5px;
      width: 75%;
      padding: 10px;
      margin-top: 30px;
  }

  .spec-item {
      display: flex;
      align-items: center;
  }

  .spec-item span {
      margin-left: 10px;
      opacity: 0.5;
  }

  .spec-item span:hover {
      cursor: pointer;
  }

  .rates-boxes {
      margin-top: 50px;
  }

  .ic-location-input-group .css-2b097c-container {
      width: 50%;
  }


  .ic-step-three {
      display: flex;
      /* flex-direction: column; */
      margin-top: 40px;
      /* justify-content: space-between; */
  }

  .ic-step-three-left {
      display: flex;
      flex: 0.5;
      flex-direction: column;
      /* margin-right: 100px; */
  }

  .ic-profile-bio {
      margin-bottom: 20px;
  }

  .ic-step-three-left textarea {
      border-color: #dddddd;
      width: 75%;
      resize: none;
  }

  .ic-step-three-right {
      flex: 0.5;
    display: flex;
    flex-direction: column;
    flex-direction: 0.5;
}

.error-message {
    margin-top: 40px;
}

.error-message p {
    color: #D94A4A;
}

.profile-pic-upload {
    margin-top: 20px;
}


.pp-upload-head {
    display: flex;
}

.css-1g6gooi input {
    width: 200px !important;
}

@media all and (min-width: 1024px) and (max-width: 1280px) {
    .pc-input-group .select-search__value {
        /* width: 235px !important; */
        /* flex: 0.4; */
    }
 }

@media all and (min-width: 768px) and (max-width: 1024px) {
    .pc-input-group {
        /* width: 40%; */
        justify-content: space-between;
    }
    .pc-input-group input {
        margin-right: 20px;
    }

    .pc-input-group .select-search {
        margin-right: 20px;
    }

    .pc-input-group .select-search__value {
        width: 196px !important;
        /* flex: 0.4; */
    }

}

@media all and (min-width: 480px) and (max-width: 768px) {
    .instructor-creation {
        padding: 20px;
        align-items: center;
    }

    .pc-personal-details h3 {
        margin-bottom: 20px;
    }

    .pc-input-row {
        flex-direction: column;
        /* align-items: center; */
        margin-bottom: 0px !important;

    }

    .pc-input-group {
        width: 100%;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    .pc-input-group input {
        flex: none;
        width: 50% !important;
    }
    .pc-header-container h1 {
        font-size: 20px;
    }

    .pc-input-group .select-search {
        flex: none;
        width: 50% !important;
    }

    .select-search input {
        width: 100% !important;
    }

    .instructor-creation .location-input-row {
        width: 100% !important;
    }

    .location-input-group-small {
        width: 40vw !important;
    }
}

@media all and (max-width: 480px) {

    .instructor-creation {
        padding: 20px;
        align-items: center;
    }

    .pc-personal-details h3 {
        margin-bottom: 20px;
    }

    .pc-input-row {
        flex-direction: column;
        /* align-items: center; */
        margin-bottom: 0px !important;

    }

    .pc-input-group {
        width: 100%;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    .pc-input-group input {
        flex: none;
        width: 50% !important;
    }
    .pc-header-container h1 {
        font-size: 20px;
    }

    .pc-input-group .select-search {
        flex: none;
        width: 50% !important;
    }

    .select-search input {
        width: 100% !important;
    }

    .instructor-creation .location-input-row {
        width: 100% !important;
    }

    .location-input-group-small {
        width: 40vw !important;
    }
}