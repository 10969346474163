.newsfeed-item {
    display: flex;
    background-color: white;
    color: black;
    align-items: center;
    /* border-bottom: 2px solid gray; */
    height: 90px;
    width: 100%;
    padding: 5px;
    box-shadow: 3px 3px 10px -1px gray;
    margin-bottom: 10px;
    transition: 200ms ease-in-out;
}

.newsfeed-item:hover {
    cursor: pointer;
    transform: scale(1.05);
    background-color: rgb(233, 233, 233);
}

.newsfeed-item-image {
transform: scale(1.5);
flex: 0.2;
display: flex;
align-items: center;
justify-content: center;
}

.newsfeed-item-content {
    display: flex;
    flex-direction: column;
    flex: 0.8;
}

.newsfeed-item-content h3 {
    margin-bottom: 2px;
    margin-top: 5px;
}

.newsfeed-item-content p {
    font-size: 14px;
    margin-right: 20px;
    text-overflow: ellipsis;
    /* white-space: nowrap; */
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
            line-clamp: 2;
    -webkit-box-orient: vertical;
}

