.settings-page {
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 79%;
    height: calc(100vh - 141px);
    overflow: scroll;
}


.settings-header {
    margin-bottom: 20px;
    /* padding: 20px; */
}

.settings-block {
    display: flex;
    flex-direction: column;
    /* border: 2px solid black; */
    border: none !important;
    box-shadow: 3px 3px 10px -1px gray !important;
    border-radius: 5px;
    margin-bottom: 50px;
}

.settings-block-header {
    margin-bottom: 10px;
}

.settings-block-row {
    display: flex;
    margin-top: 10px;
    justify-content: space-between;
    width: 70%;
}

.settings-block {
    padding: 20px;
}

.settings-button-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* margin-top: 40px; */
}

.settings-button-row button {
    color: white;
    background-color: black;
    padding: 10px 40px 10px 40px;
    border: 2px solid black;
    font-size: 20px;
    border-radius: 7px;
}

.settings-button-row button:hover {
    background-color: white;
    cursor: pointer;
    color: black;
}