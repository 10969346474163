.inbox {
    width: 100%;
    display: flex;
    /* padding-bottom: 50px; */
    height: calc(100vh - 142px);
}

.broadcast-message-container {
    display: flex;
    color: white;
    align-items: center;
    margin-left: 5%;
    width: 100%;
    /* margin-bottom: 20px; */
    transition: 200ms ease-in;
    padding: 20px 15px;
}

.broadcast-message-container:hover {
    cursor: pointer;
    color: #D94A4A !important;
}

.broadcast-message-container p {
    font-size: 16px;
    margin-left: 10px;
    font-weight: bold;
}

.broadcast-message-container .MuiSvgIcon-root {
    transform: scale(1.5);
}


.contact-list-container {
    /* height: 85vh; */
    /* padding-top: 30px; */
    background-color: #033d73;
    /* flex: 0.15; */
    width: 25vw;
    border-right: 2px solid #033d73;
    /* height: max-content; */
    overflow: hidden;
    scroll-behavior: smooth;
}

.contact-list-container > input {
    /* margin-left: 5%; */
    width: 100%;
    margin-right: 0%;
    height: 35px;
    /* margin-bottom: 10px; */
    background-color: white;
    color: grey;
    /* border: 1px solid grey; */
    border: none;
    border-bottom: 1px solid grey;
    border-radius: 2px;
    font-weight: bold;
    padding-left: 10px;
    font-size: 20px;
}
.contact-list-container-side {
    height: 100vh !important;
    padding-top: 30px;
    background-color: black;
    /* flex: 0.15; */
    width: 50vw !important;
    /* height: max-content; */
    overflow: hidden;
    scroll-behavior: smooth;
}

.contact-list-container-side > input {
    margin-left: 5%;
    width: 88%;
    margin-right: 0%;
    height: 35px;
    margin-bottom: 10px;
    background-color: black;
    color: grey;
    /* border: 1px solid grey; */
    border: none;
    border-bottom: 1px solid grey;
    border-radius: 2px;
    font-weight: bold;
    padding-left: 10px;
    font-size: 20px;
}

input {
    outline-width: 0;
}

.MuiDrawer-paper {
    display: block !important;
}
.chat-menu-drawer {
    display: block !important;
}

.chat-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    /* align-items: ; */
}

.chat-name-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0px 0px 0px;
}


.chat-input {
    display: flex;
    border-top: 1px solid lightgray;
}


.chat-input > input {
    line-height: 8.5vh;
    width: 100%;
    font-size: 1.1rem;
    background: white;
    color: black;
    outline: none;
    border: none;
    padding: 0 10px;
    outline-width: 0;
    flex: 0.95;
  }

.chat-input > button {
    flex: 0.05;
}

  .chat-input > input:focus {
    outline-width: 0;

  }

  .messages-container {
    height: 70vh;
    padding: 20px;
    background-color:#fafafa;
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: flex-end; */
    overflow: scroll;
  }

  .chat-menu-icon {
      cursor: pointer;
      transition: 200ms ease-in-out !important;
  }

  .chat-menu-icon:hover {
      color: #D94A4A !important;
  }

  .empty-container {
      width: 20vw !important;
      display: block;
  }

  .chat-name-container h2 {
      text-align: center;
  }

  .chat-type {
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0.5;
      margin-bottom: 10px;
  }


  @media all and (min-width: 1280px) {
    .chat-menu-icon {
        display: none !important;
    }
    .chat-container {
        flex: 1;
    }
    .empty-container {
        flex: 0;
    }
  }
  @media all and (min-width: 1024px) and (max-width: 1280px) {
    .contact-list-container {
        display: block;
    }

    .chat-container {
        flex: 1;
    }
    .empty-container {
        flex: 0;
    }
    .chat-menu-icon {
        display: none !important;
    }
  }

@media all and (min-width: 768px) and (max-width: 1024px) {
    .contact-list-container {
        display: block;
        flex: 0.2;
    }

    .chat-container {
        flex: 0.8;
    }

    .chat-menu-icon {
        display: none !important;
    }

    .empty-container {
        flex: 0;
    }
}

@media all and (min-width: 480px) and (max-width: 768px) {
    .contact-list-container {
        display: none;
    }

    .chat-container {
        flex: 1;
    }

    .empty-container {
        flex: 0.2
    }

    .chat-name-container {

    }

    .chat-container h2 {
        flex: 0.6;
    }

    .chat-menu-icon {
        flex: 0.2;
    }
 }

@media all and (max-width: 480px) {

    .contact-list-container {
        display: none;
    }

    .chat-container {
        flex: 1;
    }

    .empty-container {
        flex: 0.2
    }

    .chat-name-container {

    }

    .chat-container h2 {
        flex: 0.6;
    }

    .chat-menu-icon {
        flex: 0.2;
    }

}