.instructor-add-session-modal {
    z-index: 999;
    background-color: rgba(0,0,0,0.7); /* Black w/ opacity */
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.instructor-add-session-modal-content {
    border: 1px solid grey;
    border-radius: 3px;
    width: max-content;
    height: max-content;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    color: black;
    background-color: white; /* Black w/ opacity */
}

.instructor-add-session-modal-content h1 {
    margin-bottom: 20px;
}

.add-session-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.add-session-row {
    display: flex;
    align-items: center;
    margin-top: 15px;
    /* width: 40vw; */
    /* justify-content: space-between; */
    width: 100%;

}

.add-session-item {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.add-session-item input {
    width: 100%;
    border: none;
    box-shadow: 3px 3px 10px -1px gray;

}

.confirm-button {
    transition: 200ms ease-in-out;
}

.instructor-add-session-modal .confirm-button {
    margin-left: 0px !important;
    margin-top: 10px;
}

.add-session-item .select-search__input {
    box-shadow: 3px 3px 10px -1px gray !important;

}

.success-msg {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* justify-content: space-around; */
    width:20vw;
    height: 20vh;
}

.add-session-item > p {
    /* margin-right: 20px; */
    width: 100px !important;
    margin-right: 20px;
}

.add-session-item .select-search {
    width: 100%;
}

.price-group {
    display: flex;
    align-items: center;
    width: 100%;

}

.price-group p {
    margin-right: 10px;
}

@media all and (min-width: 1024px) and (max-width: 1280px) { }

@media all and (min-width: 768px) and (max-width: 1024px) { }

@media all and (min-width: 480px) and (max-width: 768px) { }

@media all and (max-width: 480px) {

    .instructor-add-session-modal-content {
        width: 90vw !important;
    }
 }