.reply-review-modal {
    z-index: 999;
    background-color: rgba(0,0,0,0.7); /* Black w/ opacity */
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.reply-review-modal-content {
    border: 1px solid grey;
    border-radius: 3px;
    width: max-content;
    height: max-content;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    color: white;
    background-color: rgba(0,0,0,0.95); /* Black w/ opacity */
}

.reply-review-modal-content .MuiSvgIcon-root {
    fill: white !important;
}

.response-type-group {
    display: flex;
    margin-bottom: 20px;
}

.response-button {
    font-size: 18px;
    background-color: white;
    border: none;
    padding: 5px;
}

.response-button-right {
    border-radius: 0px 3px 3px 0px;
    width: 100px;
}

.response-button-left {
    border-radius: 3px 0px 0px 3px;
    width: 100px;

}

.response-button:hover {
    cursor: pointer;
}

.selected-response-button {
    transform: scale(1.05);
    font-size: 18px;
    border: none;
    padding: 5px;
    background-color: #033d73;
    color: white
}

.reply-review-button-row {
    display: flex;
    align-items: center;
    justify-content: flex-end !important;
    width: 100%;
}

.reply-review-error {
    display: flex;
    width: 100%;
    align-items: center;
    color: #D94A4A;
    margin-bottom: 20px;
}

.reply-review-header {
    margin-bottom: 20px;
}

.reply-review-header p {
    transition: 200ms ease-in;
}