.login {
    height: max-content;
    min-height: calc(100vh - 141px);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 90px 0px;
}

@media all and (min-width: 1024px) and (max-width: 1280px) { }

@media all and (min-width: 768px) and (max-width: 1024px) { }

@media all and (min-width: 480px) and (max-width: 768px) {
    .login-form {
        width: 70vw !important;
    }
 }

@media all and (max-width: 480px) {
    .login-form {
        width: 90vw !important;
    }
 }