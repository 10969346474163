.booking-detail-modal {
    z-index: 799;
    background-color: rgba(0,0,0,0.7); /* Black w/ opacity */
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.booking-detail-modal-content {
    box-shadow: 3px 3px 10px -1px black;
    border-radius: 3px;
    width: 50vw;
    height: 45vh;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    color: black;
    background-color: white; /* Black w/ opacity */
}

.booking-date-group p {
    margin-top: 10px;
}

.booking-button-row {
    display: flex;
    /* width: 75%; */
    align-items: center;
    justify-content: flex-end !important;
}

.close-button {
    background-color: #023e73;
    border-color: #023e73;
    color: white !important;
    transition: 300ms ease-in;
    margin-left: 20px;
}

.close-button:hover {
    cursor: pointer;
    color: #023e73 !important;
    background-color: transparent;
}

.cancel-back-button {
    background-color: #D94A4A !important;
    border-color: #D94A4A;
    transition: 300ms ease-in;
}

.cancel-back-button:hover {
    cursor: pointer;
    background-color: transparent !important;
    color: #D94A4A;
}

.cancel-available-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-evenly;
}

#confirm-button:disabled {
    background-color: grey;
    border: grey;
}

#confirm-button:disabled:hover {
    cursor: auto;
}

.booking-detail-modal-content #confirm-button {
    margin-left: 20px;
}

@media all and (min-width: 1024px) and (max-width: 1280px) { }

@media all and (min-width: 768px) and (max-width: 1024px) { }

@media all and (min-width: 480px) and (max-width: 768px) {
    .booking-detail-modal-content {
        height: max-content;
        width: 80vw !important;
    }

    .booking-detail-modal-content h1 {
        margin-bottom: 10px;
    }

    .booking-detail-modal-content p {
        margin-bottom: 10px;
    }

    .booking-detail-modal-content .booking-button-row {
        justify-content: space-between !important;
    }
}

@media all and (max-width: 480px) {
    .booking-detail-modal-content {
        height: max-content;
        width: 80vw !important;
    }

    .booking-detail-modal-content h1 {
        margin-bottom: 10px;
    }

    .booking-detail-modal-content p {
        margin-bottom: 10px;
    }

    .booking-detail-modal-content .booking-button-row {
        justify-content: space-between !important;
    }
 }