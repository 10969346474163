.facility-profile {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    width: 100%;
    padding-bottom: 50px;
    padding: 20px 20px 50px 20px;
}

.listing-header {
    display: flex;
    flex-direction: column;
}

.fp-header-row {
    display: flex;
    align-items: center;
    margin-top: 5px;
}

.carousel-container {
    width: 100%;
    margin-top: 20px;
}

.review-container {
    display: flex;
    align-items: center;
    margin-right: 20px;
}

.listing-address {
    opacity: 0.5;
}

.review-container p {
    margin-left: 5px;
}

.facility-profile .image-gallery-content .image-gallery-slide .image-gallery-image {
    height: 25vh !important;
    object-fit: cover !important;
    width: 100% !important;
}

.facility-profile .image-gallery-content.fullscreen .image-gallery-slide .image-gallery-image {
    height: auto !important;
}

.facility-profile h2 {
    margin-top: 20px;
    font-size: 22px;
    opacity: 0.8;
}

.cap-fac {
    margin-top: 5px;
    display: flex;
    align-items: center;
    opacity: 0.6;
}

.fp-container {
    display: flex;
    width: 100%;
}

.fp-left {
    flex: 0.6;
}

.fp-right {
    flex: 0.4;
    display: flex;
    flex-direction: column;
}

.fp-left hr {
    margin: 20px 0px;
    opacity: 0.5;
    width: 90%;
}

.fp-description p {
    width: 90%;
}

.cap-fac .divider {
    margin: 0px 15px;
}

.fac-icons {
    display: flex;
    align-items: center;
}

.fp-fac-amen {
    display: flex;
}

.fp-fa-right {
    margin-left: 50px;
}

.fp-fac-item {
    display: flex;
    align-items: center;
}

.fp-fac-amen h4 {
    margin-bottom: 5px;
}

.fp-fac-item p {
    margin-left: 10px;
}

.n-a-cont {
    position: fixed;
    /* top: 400px; */
    /* bottom: 114px; */
    /* margin-bottom: 114px; */
}