.next-available {
    padding: 20px;
    display: flex;
    flex-direction: column;
    box-shadow: 3px 3px 10px -1px gray;
    margin-top: 50px;
}

.rating-container {
    display: flex;
    align-items: center;
}

.top-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.rates-container {
    display: flex;
    align-items: center;
    opacity: 0.7;

}

.base-rate {
    /* opacity: 0.7; */
    margin-right: 10px;
}

.next-avail-container {
    margin-top: 10px;
    display: flex;
    flex-direction: column;


}

.fp-right {
    position: sticky;
    right: 20px;
}

.next-avail-container p {
    margin-bottom: 5px;
}

.next-available .button-row {
    margin-top: 20px;
    justify-content: flex-end !important;
}