.analytics {
    /* display: flex; */
    min-height: calc(100vw - 144px);
}

.analytics-menu-container {
    flex: 0.15;
    height: 100%;
    overflow-x: hidden;
    z-index: 1;
    position: fixed;
}

.analytics-content-container {
    padding: 15px 30px 0px 30px;
    flex: 0.85;
    width: 85vw;
    margin-left: 15vw;
}

.analytics-menu {
    background-color: #033d73;
    color: white;
    height: 100%;
    width: 100%;
    min-height: 82vh;
}

.analytics-menu-item {
    padding-top: 12px;
}

.analytics-menu-item > hr {
    margin-left: 5%;
    margin-top: 10px;
    height: 1px;
    width: 90%;
    color: grey;
    background-color: grey;
    opacity: 0.8;
}

.analytics-menu-item > p {
    margin-left: 7%;
    /* font-weight: bold; */
    /* text-transform: uppercase; */
    font-size: 18px;
    cursor: pointer;
}

.analytics-menu-item > p:hover {
    color: #D94A4A;
}

.active {
    color: #D94A4A;
}

.analytics-home-info-card-row {
    display: flex;
    justify-content: space-evenly;
}

.drop-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.anal-menu-icon {
    cursor: pointer;
    transition: 100ms ease-in-out;
    display: none;
}

.anal-menu-icon:hover {
    color: #D94A4A;
}

.drawer-menu {
    width: 100% !important;
}

.review-stats-row {
    flex-wrap: wrap;
}

@media all and (min-width: 1280px) {
    .anal-menu-icon {
        display: none !important;
    }
    .client-thumbnail {
        width: 20%;
    }
 }
@media all and (min-width: 1024px) and (max-width: 1280px) {
    .anal-menu-icon {
        display: none !important;
    }
    .client-thumbnail {
        width: 25%;
    }
 }

@media all and (min-width: 768px) and (max-width: 1024px) {
    .anal-menu-icon {
        display: none !important;
    }

    .analytics-menu-item p {
        font-size: 95%;
    }

    .info-card {
        width: 25vw !important;
    }

    .client-thumbnail {
        width: 25%;
    }
 }

@media all and (min-width: 480px) and (max-width: 768px) {
    .anal-menu-icon {
        display: block;
    }
    .analytics-menu-container {
        display: none !important;
    }
    .analytics-content-container {
        flex: 1 !important;
    }
    .stats-box-container {
        width: 100%;
    }
    .stats-box-row {
        flex-direction: column;
    }
    .review-stats-row {
        flex-direction: column;
    }

    .metrics-row {
        flex-wrap: wrap;
    }

    .analytics .info-card {
        width: 100% !important;
    }

    .review-content {
        flex-direction: column;
    }

    .review-content img {
        margin-right: 0px !important;
        margin-bottom: 20px;
    }

    .review-button-row {
        justify-content: space-between !important;
    }

    .review-button-row button {
        font-size: 16px;
        margin: 0px;
    }
    .no-clients h3 {
        text-align: center;
    }

    .client-thumbnail {
        width: 40%;
    }

    .ct-bottom {
        text-align: center;
    }

    .selected-client-header img {
        margin-right: 10px !important;
    }

    .client-card-row {
        flex-direction: column;
    }

    .bar-chart-select .select-search {
        width: 50% !important;
    }

    .analytics-content-container {
        margin-left: 0px !important;
        width: 100vw !important;
    }
 }

@media all and (max-width: 480px) {


.analytics-content-container {
    margin-left: 0px !important;
    width: 100vw !important;

}
    .anal-menu-icon {
        display: block;
    }

    .analytics-menu-container {
        display: none !important;
    }

    .analytics-content-container {
        flex: 1 !important;
    }

    .stats-box-container {
        width: 100%;
    }
    .stats-box-row {
        flex-direction: column;
    }

    .metrics-row {
        flex-wrap: wrap;
    }

    .business-performance .info-card {
        width: 100% !important;
    }

    .review-stats-row {
        flex-direction: column;
    }

    .info-card {
        width: 100% !important;
    }

    .review-content {
        flex-direction: column;
    }

    .review-content img {
        margin-right: 0px !important;
        margin-bottom: 20px;
    }

    .review-button-row {
        justify-content: space-between !important;
    }

    .review-button-row button {
        font-size: 16px;
        margin: 0px;
    }

    .no-clients h3 {
        text-align: center;
    }

    .client-thumbnail {
        width: 40%;
    }

    .ct-bottom {
        text-align: center;
    }

    .selected-client-header img {
        margin-right: 10px !important;
    }

    .client-card-row {
        flex-direction: column;
    }

    .bar-chart-select .select-search {
        width: 50% !important;
    }

 }