.review-page {
    display: flex;
    flex-direction: column;
    /* margin-bottom: 40px; */
    min-height: calc(100vh - 144px);
}

.review-header-row {
    /* padding: 20px; */
    margin-top: 20px;
}

.review-header-row h2 {
    text-decoration: underline;
}

.review-stats-row {
    display: flex;
    justify-content: space-between;
}

