.news-feed {
    /* border: 2px solid grey; */
    /* margin-top: 40px; */
    border-radius: 5px;
    /* overflow: scroll;
    scroll-behavior: smooth; */
    /* background-color: black; */
    box-shadow: 3px 3px 10px -1px gray;
    padding-top: 10px;

}

#news-hr {
  /* width: 90%; */
  opacity: 0.8;
}

.feed-container {
  /* background-color: black; */
  overflow: scroll;
  scroll-behavior: smooth;
  height: 40vh;
  border-radius: 0px 0px 10px 10px;
  padding: 0px 20px;
}

.news-feed p {
  /* color: white; */
}

.news-feed > h2 {
    /* text-transform: uppercase; */
    color: black;
    /* background-color: #D94A4A; */
    /* padding-top: 20px; */
    font-size: 25px;
    padding: 2px 0 2px 10px;
    margin-bottom: 20px;
    border-radius: 5px 5px 0px 0px;
    text-align: center;
  }

/* always show scrollbars */

/* ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  } */