.cert-item {
    box-shadow: 3px 3px 10px -1px gray;
    display: flex;
    align-items: center;
    /* width: 30vw; */
    flex: 0.3;
    margin: 0px 20px 20px 0px;
}

.cert-item-left {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.cert-item-left img {
    height: 20vh;
    width: 20vh;
    object-fit: cover;
    border-radius: 50%;
}

.cert-item-group {
    display: flex;
}

.certificates-container {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
}

.ci-accepted {
    color: green;
}

.ci-not-accepted {
    color: #D94A4A;
}