.dropdown-menu {
    top: 80px;
    right: 0vw;
    position: absolute;
    z-index: 980;
    background-color: #033d73;
    color: white;
    padding: 5px 20px 10px 10px;
    transition: 1s ease-in;
    box-shadow: 3px 6px 10px -1px gray;
    border-radius: 0px 0px 0px 5px;
    border: 1px solid silver;
    border-top: none;
    border-right: none;
    /* width: 20vw; */
}

.dropdown-menu p {
    /* text-transform: uppercase; */
    /* font-size: 20px; */
    margin-bottom: 10px;
    width: 10vw;
    padding: 10px 20px 10px 0px;
    transition: 200ms ease-in;
    border-bottom: 1px white groove;
}

.dropdown-menu p:hover {
    color: #D94A4A;
    cursor: pointer;
}