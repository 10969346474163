.landing-page {
/* height: calc(100vh - 141px); */
width: 100vw;
}

.lp-header {
    margin-bottom: 40px;
    padding: 20px;
}

.lp-header p {
    margin-top: 10px;
    font-size: 18px;
}
.carousel-root {
    width: 90vw !important;
}

.lp-ic-header {
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lp-ic {
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 80vw; */
}



.lp-details img {
    height: 25vw;
    width: 25vw;
    object-fit: cover;
    border-radius: 40px;
    border: 4px solid black;

}

.lp-details {
    display: flex;
    justify-content: space-around;
    /* width: 80vw; */
}

.lpd-left {
    flex: 0.45;
    display: flex;
    background-color: #edefef;
    border-radius: 20px;
    border: 1px solid lightgray;
    
}

.lp-button-row {
    display: flex;
    align-items: center;
    width: 100vw;
    justify-content: center;
    margin-top: 100px;
}

.lp-button-row button {
    border-radius: 7px;
    padding: 10px 50px;
    color: white;
    cursor: pointer;
    font-weight: bold;
    font-size: 18px;

}

.carousel .slide .legend {
    font-size: 30px !important;
    opacity: 0.8 !important;
    /* height: 100%; */
    /* width: 100% !important; */
}

.lp-login {
    margin-right: 100px;
    background-color: #033d73;

}

.lp-signup {
    background-color: #033d73;

}

.lp-dl-right {
    padding: 20px;
    display: flex;
    align-items: center;
    /* margin-left: 20px; */
}

.lp-dr-right {
    padding: 20px;
    display: flex;
    align-items: center;
}

.lpd-right {
    border: 1px solid lightgray;
    flex: 0.45;
    display: flex;
    background-color: #edefef;
    border-radius: 20px;
    flex-direction: row-reverse;
}

.lp-dl-left {
    /* display: flex;
    align-items: center;
    justify-content: center; */
}