.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    background-color: #033d73;
    z-index: 999;
    width: 100%;
    top: 0;
    height: 80px;
}

.header-icons-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.header-img {
    object-fit: contain;
    height: 50px;
    width: auto;
    margin: 10px 0px 10px 20px;
}

.header-img-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.header-img {
    transition: 100ms ease-in opacity;
}

.header-items {
    margin-left: 50px;
    display: flex;
    width: 50%;
    justify-content: space-evenly;
    /* background-color: wheat; */
    transition: 100ms ease-in opacity;

}

.header-items > p {
    color: white;
    text-transform: uppercase;
    font-weight: bold;
}
.header-link {
    text-decoration: none;
    color: white;
    /* text-transform: uppercase; */
    font-weight: bold;
    transition-duration: 200ms;
    transition-timing-function: ease-in;
    /* transition-property: color; */
}
.header-link-active {
    color: #D94A4A;
    border-bottom: 2px solid #D94A4A;

}

.header-link:hover {
    color: #D94A4A;
}

#active {
    color: #D94A4A;
}

.header-icon {
    transition: 100ms ease-in opacity;

    color: white;
    margin-right: 30px;
    transform: scale(1.2);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 8vw;

}



.header-icon p {
font-size: 7px;
margin-right: 10px;
margin-left: 5px;
text-transform: uppercase;
font-family: 'LibreFranklin';
}

.header-items .active {
    border-bottom: 2px solid #D94A4A;

}

.header-icon p:hover {
    cursor: pointer;
    color: #D94A4A !important;
}

.person-icon:hover {
    cursor: pointer;
    color: #D94A4A !important;
}

.red-icon {
    color: #D94A4A;
}

.hover-red:hover {
    cursor: pointer;
    color: #D94A4A !important;
}

.person-icon {
    /* z-index: -1; */
    /* position: absolute; */
    transition: 300ms ease-in !important;

}

.new-notification-number {
    position: absolute;
    /* bottom: 5px; */
    color: white;
    background-color: #D94A4A;
    font-size: 6px !important;
    margin-left: 20px !important;
    /* padding-left: 2px; */
    padding: 3px;
    opacity: 0.9;
    border-radius: 50%;
}

.new-notification-number:hover {
    cursor: pointer !important;
}


.header-icon-container {
    display: flex;
    align-items: center !important;
    justify-content: center !important;
}

.header-icon-container p {
    /* margin: 0px !important; */
}

.header-profile {
    transition: 100ms ease-in opacity;

    display: flex;
    align-items: center;
}

.header-profile:hover {
    color: #D94A4A !important;
    cursor: pointer;
}

.header-profile p {
    color: white;
    font-family: 'LibreFranklin';
    margin-right: 20px;
    /* text-transform: uppercase; */
    transition: 200ms ease-in;
    align-items: center;
}

.header-profile p:hover {
    cursor: pointer;
    color: #D94A4A;
}

.header-profile img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
}

.header-profile img:hover {
    cursor: pointer;
}

.header-menu-icon {
    color: white !important;
    transition: 100ms ease-in-out !important;
    transform: scale(1.2);
    cursor: pointer;
    display: none;
}

.header-menu-icon:hover {
    color: #D94A4A !important;
}

.css-1nvnyqx-MuiPaper-root-MuiDrawer-paper {
    background-color: black !important;
    padding: 20px;
    font-size: 20px;
}

@media all and (min-width: 1281px)  {
    .header-menu-icon {
        display: none !important;
    }
}


@media all and (min-width: 1024px) and (max-width: 1280px) {
    .header-menu-icon {
        display: none !important;
    }
}

@media all and (min-width: 768px) and (max-width: 1024px) {
    .header-img {
        width: 10vw !important;
        height: auto;
    }

    .header-menu-icon {
        display: none !important;
    }

    .header-items {
        margin-left: 0px !important;
        width: 60%;
    }
 }

@media all and (min-width: 480px) and (max-width: 768px) {
    .header-items {
        display: none;
    }

    .header-img {
        width: 30vw;
        height: auto;
    }

    .header-profile-name {
        display: none;
    }

    .header-menu-icon {
        display: block;
    }

    .header-icons-container {
        width: 100%;
    }

    .header-icon {
        margin-left: 30px;
        margin-right: 10px !important;
    }

    .header-profile {
        margin-left: 10px;
        margin-right: 10px;
    }





 }

@media all and (max-width: 480px) {
    .header-items {
        display: none;
    }

    .header-icons-container {
        width: 100%;
    }

    .header-img {
        width: 30vw;
        height: auto;
    }

    .header-profile-name {
        display: none;
    }

    .header-menu-icon {
        display: block;
    }

    .header-icon {
        margin-left: 30px;
        margin-right: 10px !important;
    }

    .header-profile {
        margin-left: 10px;
        margin-right: 10px;
    }

    .css-i4bv87-MuiSvgIcon-root {
        margin-left: 10px;
    }
}