.business-performance {
    display: flex;
    flex-direction: column;
}

.business-performance-header {
    padding: 20px;
}

.business-performance-header > h2 {
    text-decoration: underline;
}

.business-performance-boxes {
    margin-top: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.business-performance-boxes .vr {
    border-left: 2px solid black;

}

.business-performance-chart-row {
    display: flex;
    margin-top: 50px;
}

.chart-container {
    flex: 0.5;
}

.line-container {
    flex: 0.5;
}

.business-performance .info-card {
    width: 20vw;
}

.business-performance .stats-box-container {
    width: 100% !important;
}

.business-performance .stats-box-row {
    width: 90% !important;
}

.business-performance .stats-box h1 {
    margin-top: 20px;

}

.business-performance .stats-box {
    border-radius: 7px;
}

