.view-listing {
    display: flex;
    flex-direction: column;
    padding: 20px;
    box-shadow: 3px 3px 10px -1px gray;
    margin: 40px;
}

.view-listing-content {
    display: flex;
    flex-direction: column;
}

.view-listing-address {
    margin-top: 10px;
}

.view-listing-types p {
    opacity: 0.5;
    font-size: 16px;
}

.view-listing-carousel {
    padding: 50px 200px 50px 200px;
}

.view-listing .back-row {
    padding-left: 0px !important;
    margin-bottom: 10px;
}

.view-listing-details {
    display: flex;
    width: 100%;
}

.view-listing-details-left {
    flex: 0.5;
}

.view-listing-details-left p {
    margin-top: 10px;
    margin-bottom: 20px;
}

.view-listing-details-right {
    flex: 0.5;
    display: flex;
    width: 100%;
    flex-direction: column;

}

.view-listing-details-right li {
    text-transform: capitalize;
}

.view-listing-max-capacity {
    margin-top: 20px;
    /* display: flex;
    align-items: center !important; */
}

.view-listing-max-capacity p {
    /* font-size: 19px; */
    margin-top: 5px;
}



.view-listing-rates {
    margin-top: 20px;
}

.view-listing-rates h3 {
    margin-bottom: 10px;
}

.view-listing-rates p {
    margin-bottom: 5px;
}

.view-listing-selected {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.view-listing-selected h3 {
    margin-bottom: 5px;
}

.view-listing-facilities {
    flex: 0.5;
}

.view-listing-amenities {
    flex: 0.5;
}

.view-listing-button-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}

.view-listing-button-row button {
    margin-left: 0px !important;
    margin-bottom: 20px !important;
}

.price-container {
    margin-top: 20px;
}

.price-item {
    /* border: 1px solid lightgrey; */
    box-shadow: 3px 3px 10px -1px gray;
    padding: 5px;
    width: 70%;
}

.view-listing .price-item {
    margin-bottom: 10px;
}

.view-listing .image-gallery-content .image-gallery-slide .image-gallery-image {
    height: 40vh !important;
}


@media all and (min-width: 1024px) and (max-width: 1280px) { }

@media all and (min-width: 768px) and (max-width: 1024px) { }

@media all and (min-width: 480px) and (max-width: 768px) { }

@media all and (max-width: 480px) {

    .view-listing-carousel {
        padding: 0px;
        margin: 20px 0px;
    }

    .view-listing-details {
        flex-direction: column;
        margin-bottom: 20px;
    }
}