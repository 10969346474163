.footer {
    padding: 10px 0 10px 0;
    background-color: #033d73;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    bottom: 0;
    left: 0;
    /* position: fixed; */
}

.footer > p {
    font-size: small;
    color: white;
}

#reserved-text {
    margin-top: 10px;
}