.class-item {
    box-shadow: 3px 3px 10px -1px gray;
    display: flex;
    /* align-items: center; */
    /* justify-content: center; */
    /* justify-content: space-evenly; */
    padding: 10px;
    flex-direction: column;
}

.ci-container {
    display: flex;
    align-items: center;
}

.ci-container h2 {
    margin-bottom: 10px;
}

.class-item-left {
    flex: 0.3;
    display: flex;
    justify-content: center;
    align-items: center;
}

.class-item-left img {
    height: 15vh;
    width: auto;
    border-radius: 50%;
}

.class-item-right {
    flex: 0.7;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.class-detail-item {
    display: flex;
    align-items: center;
}

.class-detail-item p {
    margin-left: 20px;
}

.class-button-row {
    justify-content: flex-end !important;
}

.class-button-row .cancel-button {
    margin-right: 20px;
}

@media all and (min-width: 1024px) and (max-width: 1280px) { }

@media all and (min-width: 768px) and (max-width: 1024px) { }

@media all and (min-width: 480px) and (max-width: 768px) { }

@media all and (max-width: 480px) {

.class-item {
    width: 80vw !important;
}

}