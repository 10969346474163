.edit-listing {
    display: flex;
    flex-direction: column;
    box-shadow: 3px 3px 10px -1px gray;
    margin: 40px;
}

.edit-listing h1 {
    margin-left: 20px;
    margin-top: 20px;
    text-decoration: underline;
}

.edit-listing-container {
    display: flex;
    justify-content: space-between;
}

.edit-listing-left {
    flex: 0.5;
}

.back-row {
    display: flex;
    align-items: center;
    padding: 20px 0px 0px 20px;
    cursor: pointer;
}

.back-row p {
    margin-left: 20px;
}

.edit-listing-right {
    flex: 0.5;
}

.edit-listing-button-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 20px;
}

.listing-name-group-edit {
    width: 100%;
    display: flex;
    margin-left: 20px;
    margin-top: 20px;
    flex-direction: column;
}

.listing-name-group-edit input {
    margin-top: 10px;
    padding: 10px;
    width: 30vw;
    font-size: medium;
    box-shadow: 3px 3px 10px -1px gray;
    border: none;
}

.css-yk16xz-control {
    box-shadow: 3px 3px 10px -1px gray;
    border: none;
}

.edit-listing-delete-function {
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.edit-listing-delete-function p {
    margin-top: 7px;
    margin-bottom: 7px;
}

.edit-listing-delete-function input {
    padding: 5px;
    font-size: 18px;
}

.black-button {
    margin-left: 50px;
    font-size: 18px;
    background-color: black;
    color: white;
    border: 1px solid black;
    border-radius: 5px;
    padding: 7px 20px 7px 20px;
    transition-duration: 300ms;
    transition-timing-function: ease-in;
    /* transition-property: background; */
}

.black-button:hover {
    cursor: pointer;
    background-color: white;
    color: black;
}

.black-button:disabled {
    background-color: grey;
    border-color: grey;
}

.black-button:disabled:hover {
    cursor: auto;
    color: white;
}