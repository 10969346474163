.listing {
    box-shadow: 3px 3px 10px -1px gray;
    display: flex;
    flex-direction: column;
    width: 45vw;
    margin-left: 3%;
    margin-top: 3%;
    border-radius: 5px;
    height: max-content;
    /* height: 600px; */
    background-color: rgb(249, 249, 249);
}

.listing-details {
    /* margin-top: 20px; */
    display: flex;
    /* padding-top: 20px; */
    padding-left: 20px;


}

.listing p  {
    font-size: 14px;
}

.listing h2 {
    font-size: 18px;
}

.listing-title {
    /* padding-top: 20px; */
    padding-left: 20px;
}

.listing-title > h1 {
    text-decoration: underline;
    font-size: 23px !important;
    margin: 10px 0px !important;

}

.listing-images {
    flex: 0.5;
    margin-right: 20px;
    display: flex;
    align-items: center;
    border-right: 5px;
    justify-content: center;
}

.listing-description {
    flex: 0.5;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 200px
}


.listing-button-row {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.description-header {
    flex: 0.1;
}

.description-container {
    flex: 0.9;
    border: 1px solid grey;
    border-radius: 5px;
    padding: 10px;
    margin-right: 20px;
    margin-top: 20px;
    height: 100px !important;
    overflow-y: scroll;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
    border-right: 5px;
    height: 20vh !important;
    /* width: auto !important; */
    object-fit: cover;
    border-radius: 5px;
    width: 100%;
}

.image-gallery-content .image-gallery-slide .image-gallery-image img {
    height: 100%;
    width: auto;
}

.listing .image-carousel {
    width: 100%;
}


.list-button {
    font-weight: bold;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 20px;
    padding: 10px 50px 10px 50px;
}

.list-button:hover {
    cursor: pointer;
}

.edit-button {
    border: 1px solid #D94A4A;
    background-color: #D94A4A;
    margin-right: 10px;
    transition-duration: 300ms;
    transition-timing-function: ease-in;
    font-size: 15px;
    padding: 10px 40px;
}

.edit-button:hover {
    background-color: white;
    color: #D94A4A;
}

.view-button {
    background-color: #023e73;
    margin-left: 10px;
    border: 1px solid #023e73;
    transition-duration: 300ms;
    transition-timing-function: ease-in;
    font-size: 15px;
    padding: 10px 40px;
}

.view-button:hover {
    background-color: white;
    color: #023e73;
}

