.instructor-booking-request-modal {
    z-index: 999;
    background-color: rgba(0,0,0,0.7); /* Black w/ opacity */
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.instructor-booking-request-modal-content {
    border: 1px solid grey;
    border-radius: 3px;
    width: max-content;
    height: max-content;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    color: black;
    background-color: white; /* Black w/ opacity */
}

.cancellation-policy {
    opacity: 0.7;
    margin: 10px 0px 10px 0px;
}

.booking-policy {
    opacity: 0.7;
    margin: 10px 0px 10px 0px;
}

.booking-notes-container {
    margin: 20px 0px;
    border: 1px solid grey;
    padding: 10px;
    border-radius: 5px;
}

.booking-request-row {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 10px;
}

.fat-pee {
    /* flex: 0.1; */
    font-weight: bold;
}

.booking-request-times {
    width: 100%;
}

.middle-pee {
    margin-left: 10px;
    flex: 0.5;
}


.react-datetime-picker {
    flex: 0.3;
}

.booking-request-details {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.booking-error-row {
    display: flex;
}

.booking-error-row p {
    transition: 200ms ease-in;
    color: #D94A4A;
}

.booking-details-row {
    display: flex;
    /* width: 60%; */
    align-items: center;
    justify-content: space-between;
}

.booking-request-button-row {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
}

.yc-card-input-wrapper {
    box-shadow: 3px 3px 10px -1px gray !important;
    margin: 5px 0px 20px 0px;
    /* padding: 20px 10px; */
}

.yc-card-input-wrapper iframe {
    /* padding: 20px; */
    /* background-color: pink !important; */
    font-weight: bold;

}

.yc-field-group input {
    /* color: pink; */
    padding: 20px;
    font-weight: bold;
}



.remaining-credits-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.credits-info-container {
    margin-top: 20px;
}

#pay-button {
    background-color: black;
    border-color: black;
    cursor: pointer;
    transition: 200ms ease-in-out;
}

#pay-button:disabled {
    background-color: grey !important;
    border-color: grey !important;

}

#pay-button:hover {
    background-color: transparent;
    color: black;
}

.instructor-booking-request-modal .button-row {
    justify-content: center;
}



.booking-request-button-row button {
    font-size: 18px;
    padding: 7px 25px 7px 25px;
    border-radius: 5px;
    color: white;
}

.confirm-button {
    background-color: #023e73;
    border: 1px solid #023e73;
    margin-left: 20px;
}

.confirm-button:disabled {
    background-color: lightgray;
    border: 1px solid lightgray;
    color: black;
}

.confirm-button:disabled:hover {
    cursor: auto;
    background-color: lightgray;
    color: black;
}

.confirm-button:hover {
    cursor: pointer;
    background-color: transparent;
    color: #023e73;
}

.instructor-booking-request-modal-content .general-button {
    background-color: black !important;
    border-color: black;
    cursor: pointer;
    margin-top: 20px;
}

.booking-completed {
    padding: 20px;
}

.cancel-button {
    background-color: #D94A4A;
    border: 1px solid #D94A4A;
}

.cancel-button:hover {
    background-color: transparent;
    cursor: pointer;
    color: #D94A4A;
}

.booking-request-button-row button:active {
    background-color: white;
}

.enabled-decreases {
    text-transform: capitalize;
    /* margin-top: 10px !important; */
    margin-left: 20px;
}

.enabled-increases {
    margin-top: 10px !important;
    text-transform: capitalize;
    margin-left: 20px;
}

.change-item {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.rate-row {
    margin-bottom: 10px !important;
}

#confirm-button:hover {
    background-color: transparent !important;
    color: #023e73;
}

#cancel-button:hover {
    background-color: transparent !important;
    color: #D94A4A;
}

.c-i-left {
    display: flex;
    align-items: center;
}

.c-i-left .MuiSvgIcon-root {
    color: grey;
    margin-left: 20px;
}

@media all and (min-width: 1024px) and (max-width: 1280px) { }

@media all and (min-width: 768px) and (max-width: 1024px) {
    .instructor-booking-request-modal-content {
        height: max-content !important;
        width: 90vw !important;
    }

    .instructor-booking-request-modal-content h1 {
        font-size: 20px;
    }

    .booking-request-row {
        flex-direction: column !important;
        align-items: flex-start;
        margin-top: 20px;
    }

    .middle-pee {
        margin: 10px 0px;
    }

    .booking-details-row {
        width: 100%;
        margin-bottom: 5px;
    }

    .booking-request-button-row {
        width: 100%;
        margin-top: 20px;
    }
}

@media all and (min-width: 480px) and (max-width: 768px) {
    .instructor-booking-request-modal-content {
        height: max-content !important;
        /* width: 90vw !important; */
    }

    .instructor-booking-request-modal-content h1 {
        font-size: 20px;
    }

    .booking-request-row {
        flex-direction: column !important;
        align-items: flex-start;
        margin-top: 20px;
    }

    .middle-pee {
        margin: 10px 0px;
    }

    .booking-details-row {
        width: 100%;
        margin-bottom: 5px;
    }

    .booking-request-button-row {
        width: 100%;
        margin-top: 20px;
    }
 }

@media all and (max-width: 480px) {
    .instructor-booking-request-modal-content {
        height: max-content !important;
        width: 90vw !important;
    }

    .instructor-booking-request-modal-content h1 {
        font-size: 20px;
    }

    .booking-request-row {
        flex-direction: column !important;
        align-items: flex-start;
        margin-top: 20px;
    }

    .middle-pee {
        margin: 10px 0px;
    }

    .booking-details-row {
        width: 100%;
        margin-bottom: 5px;
    }

    .booking-request-button-row {
        width: 100%;
        margin-top: 20px;
    }

}

* {
    font-family: 'LibreFranklin' !important;
}
        .one-liner {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
​
        .yc-card-form-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            /* width: 300px; */
        }
​
        .yc-field-group--card-number .yc-card-input-wrapper {
            border: 1px solid #dddddd;
            border-radius: 5px;
            margin-bottom: 10px;
            padding: 5px;
            /* width: 100%; */
            font-weight: bold;
        }
​
        .yc-expiry-cvv-holder .yc-card-input-wrapper {
            border: 1px solid #dddddd;
            border-radius: 5px;
            padding: 5px;
            margin-bottom: 10px;
            /* width: 50%; */
        }
​
        #card-frame {
            margin-bottom: 20px;
            margin-top: 20px;
        }
​
        .yc-expiry-cvv-holder {
            display: flex;
            flex-direction: column;
            padding: 5px;
        }