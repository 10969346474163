.step-one {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 20px;
    height: 80vh;
    /* margin-bottom: 40px; */
}

.edit-listing .step-one {
    height: max-content !important;
    margin-bottom: 40px;
}

.edit-listing .step-two {
    height: max-content !important;
    margin-bottom: 0px;
}

.edit-listing .step-four {
    min-height: 0px !important;
    height: max-content !important;
    margin-bottom: 0px;
}
.edit-listing .step-five {
    height: max-content !important;
    margin-bottom: 0px;
}

.step-two {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 20px;
    height: 80vh;

    /* height: 70vh; */
}
.step-three {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 20px;
    height: 80vh;
}
.step-four {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 20px;
    min-height: 80vh;
    height: max-content;
    /* margin-bottom: 20px; */
}
.step-five {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 20px;
    /* margin-bottom: 50px; */
    height: 80vh;
}
.step-six {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 20px;
    min-height: 80vh;
}
.step-seven {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 20px;
    height: 80vh;
}
.step-eight {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 20px;
    height: max-content;
}

.listing-name-group {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.listing-name-group label {
    margin-bottom: 10px;
    font-size: large;
}

.listing-name-group input {
    padding: 10px;
    width: 30vw;
    font-size: medium;
}

.rate-container {
    width: 30vw;
}

.rate-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.rate-row input {
    width: 5vw;
    text-align: center;
    font-size: 80%;
}

.address-input {
    display: flex;
    flex-direction: row;
    width: 100%;
}

#fac-header {
    font-size: 20px;
    margin-bottom: 20px;
}

#location-button {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 5px;
    margin-bottom: 20px;
    width: 250px;
}

#location-button:hover {
    cursor: pointer;
}

.location-left {
    flex: 0.5;
}

.location-right {
    flex: 0.5;
}

.location-input-group {
    display: flex;
    width: 40vw;
    flex-direction: column;
    margin-bottom: 5px;
}

.location-input-group input {
    width: 95%;
    padding: 4px;
    margin-top: 5px;
}

.location-input-group-small {
    display: flex;
    /* justify-content: space-between; */
    flex-direction: column;
    width: 20vw;
}

.location-input-group-small input {
    width: 90%;
    padding: 4px;
    margin-top: 5px;
}

.location-input-row {
    display: flex;
    justify-content: space-between;
    width: 40vw;
    margin-bottom: 10px;
}

.location-text-group {
    display: flex;
    flex-direction: column;
}

.location-text-group label {
    margin-bottom: 5px;
}

.location-text-group textarea {
    width: 90%;
    border: 1px solid lightgray;
}

.location-input-group input {
    box-shadow: 3px 3px 10px -1px gray;
    border: none;
}

.step-seven input {
    box-shadow: 3px 3px 10px -1px gray;

}

.image-drop {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none;
    box-shadow: 3px 3px 10px -1px gray;
    border-radius: 5px;
    height: 35vh;
    width: 30vw;
}

.image-drop p {
    font-size: 20px;
}

#publish-icon {
    margin-bottom: 40px;
    transform: scale(3);
    opacity: 0.7;
}

.step-three > textarea {
    margin-top: 20px;
    border: none;
    box-shadow: 3px 3px 10px -1px gray;

}

.step-one select {
    padding-left: 5px;
    font-size: 18px;
    height: 60px;
}

#drag-drop {
    opacity: 0.6;
}

.step-one input {
    padding-left: 5px;
    /* font-size: 18px; */
    height: 50px;
}

.listings h1 {
    text-decoration: underline;
    margin-bottom: 20px;
    margin-top: 30px;
    font-size: 40px;
}

.input-container {
    width: 45vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 40vh;
    /* margin-top: 40px; */
}

.venue-row {
    display: flex;
    justify-content: space-between;
}

.venue-type-drop {
    flex: 0.4;
}

.venue-space-drop {
    flex: 0.4;
}

.venue-capacity-drop {
    width: 40%;
}

.options-container {
    display: flex;
    justify-content: flex-start;
    width: 80%;
    margin-bottom: 40px;
}

.option-right {
    margin-left: 40px;
}

.box-container {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
}

.check-group {
    display: flex;
    align-items: center;
    margin-top: 5px;
}

.check-group > label {
    margin-left: 20px;
}

.check-group > label:hover {
    cursor: pointer;
    opacity: 0.7;
}

textarea {
    padding: 10px;
    outline-width: 0;
    font-size: medium;
    margin-bottom: 20px;
}

.image-container {
    display: flex;
    width: 100%;
}

.left-col {
    flex: 0.4;
    display: flex;
    flex-direction: column;
}

.right-col {
    flex: 0.6;
    display: flex;
    flex-direction: column;
}

.description-box {
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.image-description {
    margin-right: 50px;
}

.step-five .multiSelectContainer {
    width: 90%;
    margin-top: 5px;

}

.step-six .multiSelectContainer {
    /* margin-right: 20px; */
}

.step-six .top-row {
    margin-bottom: 0px !important;
}
.location-input-group-small label {
    /* margin-bottom: 5px; */
}

input {
    border: 1px solid lightgray;
    border-radius: 3px !important;
    /* height: 200% !important; */
    font-size: 90%;
    height: 40px !important
}

.multiSelectContainer input {
    height: 40px !important;
}

.step-five .searchWrapper {
    height: 40px !important;
    border: none;
    box-shadow: 3px 3px 10px -1px gray;
}

.step-five textarea {
    box-shadow: 3px 3px 10px -1px gray;

}

.zip-input {
    border: none;
    box-shadow: 3px 3px 10px -1px gray;

}

.step-one .select-search {
    width: 45%;

}

.step-one .select-search input {
    height: 50px !important
}

.location-input {
    height: 50px !important;
}

.discounts-header {
    display: flex;
    align-items: center;
    width: 20vw;
    justify-content: space-between;
}

.discount-container {
    width: max-content;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.discounts-header h4 {
    /* margin-right: 30px; */
}

.discounts-header .MuiSvgIcon-root {
    /* transform: scale(1.5); */
    cursor: pointer;
    opacity: 0.5;
    transition: 50ms ease-in-out;
}

.set-rates-info .MuiSvgIcon-root {
    cursor: pointer;
    opacity: 0.5;
    transition: 50ms ease-in-out;
}

.discounts-header .MuiSvgIcon-root:hover {
    /* transform: scale(1.3); */
}

.set-rates-info .MuiSvgIcon-root:hover {
    /* transform: scale(1.3); */
}

.base-input-container {
    display: flex;
    align-items: center;

}

.base-input-container h3 {
    margin-right: 10px;
    opacity: 0.5;
}

.step-six h2 {
    margin-top: 20px;
}

.discounts-footer {
    display: flex;
    align-items: center;
}

.discounts-footer .MuiSvgIcon-root {
    /* transform: scale(2); */
    cursor: pointer;
    fill: green;
    margin-left: 10px;
}

.set-rates-info {
    display: flex;
    align-items: center;
}

.set-rates-info p {
    margin-right: 40px;
}


/* Tooltip container */
.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
  }

  /* Tooltip text */
  .tooltip .tooltiptext {
    visibility: hidden;
    opacity: 0;
    transition: 100ms ease-in-out;
    width: 50vw;
    background-color: white;
    color: black;
    text-align: center;
    padding: 10px;
    border-radius: 6px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
    border: 2px solid grey;
  }

  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

  .tooltip span {
      /* text-align: start; */
      color: grey !important;
  }

  .bubble {
    position: relative;
    background: grey;
    color: #FFFFFF;
    /* font-family: Arial; */
    /* font-size: 20px; */
    /* line-height: 120px; */
    text-align: center;
    /* width: 250px; */
    /* height: 120px; */
    border-radius: 10px;
    padding: 0px;
    margin-left: 20px;
    box-shadow: 3px 3px 10px -1px gray;

}
.bubble:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    z-index: 1;
    border-style: solid;
    border-color: transparent grey;
    border-width: 0px 20px 20px 0;
    top: 25%;
    left: -20px;
    margin-top: -20px;
}

.set-rates-info .bubble:after {
    top: 40%;
}

.MuiIconButton-colorSecondary:hover {
    color: grey !important;
}

.MuiCheckbox-colorSecondary:hover {
    color: grey !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
    color: grey !important;
}

.policy-choices-container {
    display: flex;
    flex-direction: column;
}

.policy-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

.pg-top-row {
    align-items: center;
    display: flex;
    width: 70vw;
}

.pg-top-row h3 {
    margin-left: 20px;
}

.pg-bottom-row p {
    margin-left: 62px;
}

.step-eight > p {
    margin-top: 10px;
    margin-bottom: 20px;
}

.house-rules-container h2 {
    margin-bottom: 10px;
}

.dz-upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 3px 3px 10px -1px gray;
    padding: 100px 20px;
    margin: 20px;
    margin-right: 100px;
}

.hr-upload-container {
    display: flex;
    align-items: center;
}

.bookings-policy {
    margin: 10px 0px 20px 0px;
    display: flex;
    align-items: center;
}

.bookings-policy .selected-client-menu {
    margin-left: 40px;
}

.bookings-policy .info-icon {
    color: grey;
    margin-left: 20px;
}

.bookings-policy .tooltip {
    border: none;
}

.bookings-policy .switch {
    margin-left: 40px;
}

.venue-row p {
    width: 45%;
}

.right-col-header {
    display: flex;
    align-items: center;
}

.right-col-header h2 {
    margin-right: 40px;
}



@media all and (min-width: 1024px) and (max-width: 1280px) {
    .bubble:after {
        display: block;
    }
 }

@media all and (min-width: 768px) and (max-width: 1024px) {
    .bubble:after {
        display: block;
    }
 }

@media all and (min-width: 480px) and (max-width: 768px) {
    .bubble:after {
        display: none;
    }


    .step-eight {
        padding-left: 0px;
        padding: 20px !important;
        width: 100vw;

    }

    .dz-upload-container {
        margin-right: 20px;
    }

    .hr-upload-container {
        flex-direction: column !important;
    }

    .step-seven input {
        width: 80vw;
    }

    .step-seven {
        padding-left: 0px;
        padding: 20px !important;
        height: 65vh !important;
    }

    .tooltip .tooltiptext {
        position:fixed;
        left: 0px;
        width: 90vw;
        top: 30vh;
    }

    .step-six {
        padding-left: 0px;
        padding: 20px !important;
    }

    .discounts-header {
        width: 90vw;
    }

    .rate-container {
        width: 90vw;
    }

    .base-input-container input {
        width: 15vw;
    }

    .step-five {
        padding-left: 0px;
        padding: 20px !important;
    }

    .location-left {
        margin-bottom: 20px;
    }

    .location-input-group {
        width: 90vw !important;
    }

    .location-input-row {
        width: 90vw !important;
    }

    .address-input {
        flex-direction: column;
    }

    .image-drop {
        width: 90vw !important;
        margin-bottom: 20px;
    }

    .step-four {
        padding-left: 0px;
        padding: 20px !important;
    }

    .step-three {
        width: 100vw;
        height: max-content !important;
    }

    .step-three textarea {
        width: 90vw;
        height: 60vh;
        margin-bottom: 20px;
    }

    .image-container {
        flex-direction: column;
    }

    .step-one {
        padding-left: 0px;
        width: 100vw;
        align-items: center;
    }

    .step-two {
        padding-left: 0px;
        padding: 20px;
    }

    .input-container {
        width: 90%;

    }

    .start-button-row {
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    .start-button-row button {
        margin-left: 0px;
    }

    .step-button-row {
        width: 100%;
        padding: 0px 20px;
    }

    .back-button {
        margin-left: 0px;
    }
}

@media all and (max-width: 480px) {

    .bubble:after {
        display: none;
    }

    .step-eight {
        padding-left: 0px;
        padding: 20px !important;
        width: 100vw;

    }

    .dz-upload-container {
        margin-right: 20px;
    }

    .hr-upload-container {
        flex-direction: column !important;
    }

    .step-seven input {
        width: 80vw;
    }

    .step-seven {
        padding-left: 0px;
        padding: 20px !important;
        height: 65vh !important;
    }

    .tooltip .tooltiptext {
        position:fixed;
        left: 0px;
        width: 90vw;
        top: 30vh;
    }

    .step-six {
        padding-left: 0px;
        padding: 20px !important;
    }

    .discounts-header {
        width: 90vw;
    }

    .rate-container {
        width: 90vw;
    }

    .base-input-container input {
        width: 15vw;
    }

    .step-five {
        padding-left: 0px;
        padding: 20px !important;
    }

    .location-left {
        margin-bottom: 20px;
    }

    .location-input-group {
        width: 90vw !important;
    }

    .location-input-row {
        width: 90vw !important;
    }

    .address-input {
        flex-direction: column;
    }

    .image-drop {
        width: 90vw !important;
        margin-bottom: 20px;
    }

    .step-four {
        padding-left: 0px;
        padding: 20px !important;
    }

    .step-three {
        width: 100vw;
        height: max-content !important;
    }

    .step-three textarea {
        width: 90vw;
        height: 60vh;
        margin-bottom: 20px;
    }

    .image-container {
        flex-direction: column;
    }

    .step-one {
        padding-left: 0px;
        width: 100vw;
        align-items: center;
    }

    .step-two {
        padding-left: 0px;
        padding: 20px;
    }

    .input-container {
        width: 90%;

    }

    .start-button-row {
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    .start-button-row button {
        margin-left: 0px;
    }

    .step-button-row {
        width: 100vw;
        padding: 0px 20px;
        font-size: 20px;
    }

    .back-button {
        margin-left: 0px;
    }

 }