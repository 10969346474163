.discount-modal {
    z-index: 999;
    background-color: rgba(0,0,0,0.7); /* Black w/ opacity */
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-content {
    border: 1px solid grey;
    border-radius: 3px;
    width: 50vw;
    height: 75vh;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    color: white;
    background-color: rgba(0,0,0,0.95); /* Black w/ opacity */
}

.discount-modal h2 {
    margin-bottom: 20px;
}

.discount-row {
    padding: 5px 0 5px 0;
    display: flex;
    width: 80%;
}

.discount-row input {
    flex: 0.5;
    width: 10vw;
}

.discount-row h3 {
    flex: 0.5;
}

.discount-check-row {
    display: flex;
    width: 80%;
}

.discount-check-row h3 {
    flex: 0.5;
}

.check-col {
    flex: 0.5;
}

.discount-modal .check-group {
    display: flex;
    align-items: center;
    margin-top: 0px;
}

.check-group input {
    height: 30px !important;
}

.amount-left {
    flex: 0.5
}

.amount-right {
    flex: 0.5;
    margin-left: -4px;
}

.confirmation-message {
    margin-top: 10px;
    background-color: #D94A4A;
    padding-left: 5px;
}

.confirmation-message > p {
    padding: 5px;
    margin-top: 0;
    font-size: medium;
}

.button-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
    /* height: 0px; */
}

.button-row > button {
    text-transform: uppercase;
    padding: 10px;
    font-size: 17px;

    border-radius: 5px;
    color: white;
    font-weight: bold;
}

#confirm-button {
    background-color: #023e73;
    border: 2px solid #023e73;
    transition: 300ms ease-in;

}

#cancel-button {
    background-color: #D94A4A;
    border: 2px solid #D94A4A;
    transition: 300ms ease-in;

}

#confirm-button:hover {
    cursor: pointer;
    background-color: black;
    /* color: green; */
}

#confirm-button:active {
    /* background-color: white; */
    /* color: green; */
    /* color: green; */
}

#cancel-button:active {
    /* background-color: white !important; */
    color: #D94A4A;
    /* color: green; */
}
#cancel-button:hover {
    cursor: pointer;
    background-color: black;
    /* color: green; */
}