.statement-container {
    display: flex;
    flex-direction: column;
}

.financial-statements {
    display: flex;
}

.financial-statements-left {
    flex: 0.6;
}

.financial-statements-right {
    flex: 0.4;
}