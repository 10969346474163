.calendar-page {
    /* display: flex; */
    flex-direction: row;
    width: 100vw;
    height: calc(100vh - 141px);
    overflow: hidden;
}

.side-menu {
    display: flex;
    position: fixed;
    flex-direction: column;
    background-color: #023e73;
    color: white;
    flex: 0.15;
    width: 15vw !important;
    height: 100%;
    overflow-x: hidden;
    z-index: 1;

}

.no-facs-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 144px);
}

.rbc-time-header.rbc-overflowing {
    border: none !important;
}

.side-menu p {
    /* text-transform: uppercase; */
    font-weight: bold;
    font-family: 18px !important;
}

.main-page {
    display: flex;
    flex-direction: column;
    padding: 10px;
    flex: 0.85;
    margin-left: 15vw;
}

.drop-container select {
    width: 20vw;
    padding: 5px;
    outline-width: 0;
}

.heading-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.rbc-calendar {
    height: 60vh !important;
}

/* .rbc-day-bg.rbc-today {
    color: pink;
    background-color: pink;
} */

.calendar-filter-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.calendar-legend-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}

.calendar-legend-group span {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
}

.help-icon {
    fill: grey !important;
    cursor: pointer;
    transition: 200ms ease-in-out !important;
}

.MuiSvgIcon-root {
    transition: 200ms ease-in-out;
}

.help-icon:hover {
    transition: 200ms ease-in-out;
    transform: scale(1.1);
}

.cal-leg-item {
    margin-left: 20px;
    display: flex;
    align-items: center;
}

.cal-container {
    overflow-y: scroll;
}

.red {
    background-color: #D94A4A;
}

.black {
    background-color: black;
}

.blue {
    background-color: #023e73;
}

.green {
    background-color: green;
}

.yellow {
    background: #f2c53d;
}

.rbc-agenda-date-cell {
    display: block;
}

.rbc-agenda-time-cell span {
    width: 300px !important;
}

.drop-container .select-search {
    width: max-content !important;
}


.calendar-filter-group {
    flex: 0.2;
}

.help-div {
    display: flex;
    justify-content: flex-end;
    flex: 0.4;
}

.menu-icon-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

}

.rbc-timeslot-group {
    min-height: 70px !important;
}

.calendar-menu-drawer .MuiDrawer-paper {
    background-color: black !important;
    color: white;
}

.select-search__select {
    z-index: 200;
}

.rbc-event.rbc-selected, .rbc-day-slot .rbc-selected.rbc-background-event {
    outline: none !important;
}

@media all and (min-width: 1280px) {
    .cal-menu-icon {
        display: none !important;
    }
 }

 @media all and (min-width: 1024px) and (max-width: 1280px) {
    .cal-menu-icon {
        display: none !important;
    }
 }

@media all and (min-width: 768px) and (max-width: 1024px) {
    .cal-menu-icon {
        display: none !important;
    }
 }

@media all and (min-width: 480px) and (max-width: 768px) {
    .side-menu {
        display: none;
    }

    .calendar-filter-row {
        flex-direction: column;
    }
    .calendar-filter-group {
        margin-bottom: 20px;
    }

    .main-page {
        flex: 1;
        margin-left: 0px;

    }
    .cal-menu-icon {
        display: block;
    }

    .calendar-legend-group {
        justify-content: space-evenly;
    }
}

@media all and (max-width: 480px) {


    .cal-leg-item {
        transform: scale(0.7);
        margin-left: 0px !important;
    }

    .cal-menu-icon {
        display: block;
    }

    .side-menu {
        display: none;
    }

    .main-page {
        flex: 1;
        margin-left: 0px;
    }

    .calendar-filter-row {
        flex-direction: column;
    }
    .calendar-filter-group {
        margin-bottom: 20px;
    }


    .calendar-legend-group {
        flex: 0.3;
    }

    .rbc-toolbar-right {
        display: flex;
        justify-content: center;
    }

    .calendar-page {
        font-size: 80% !important;
    }

    .rbc-time-slot {
        /* min-width: 50px; */
        width: max-content;
    }

    .rbc-day-bg {
        width: 50px !important;
    }

    .rbc-header {
        width: 50px !important;
    }

    .rbc-time-header-gutter {
        width: 50px !important;
        max-width: 50px !important;
    }

    .rbc-timeslot-group {
        width: 50px;
    }

    .rbc-label {
        font-size: 8px;
    }
}