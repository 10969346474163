.rates-box {
    display: flex;
    flex-direction: column;
    /* width: 100%; */
    align-items: flex-start;
}

.rates-input-container {
    display: flex;
    flex-direction: column;
}

.rates-input-row {
    display: flex;
    width: 100%;
    margin-bottom: 10px;

}

.rates-input-box {
    display: flex;
    align-items: center;
    border: 1px solid #dddddd;
    border-radius: 8px;
    padding: 5px;
}

.rib-left p {
    opacity: 0.5;
    font-size: 12px;
    font-weight: bold;
}

.rib-left {
    flex: 0.6;
    padding-left: 20px;
    width: 100px;
}

.rib-right {
    flex: 0.4;
    display: flex;
    align-items: center;
    border-left: 1px solid #dddddd;
    justify-content: center;
}

.rib-right p {
    opacity: 0.5;
    font-weight: bold;
}

.rib-right input {
    border: none;
    width: 50%;
    /* padding: 0px; */
}

.rib-right input:focus {
    border-bottom: 1px solid grey;
}

.rib-right input:disabled {
    background-color: white !important;
}

.rates-box > p {
    font-size: 14px;
    margin-bottom: 20px;
    font-weight: bold;
}

.rates-box .MuiCheckbox-root {
    color: black !important;
}

.rates-online-link {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.rates-online-link p {
    font-size: 14px;
    opacity: 0.7;
    margin-bottom: 10px;
}



/* Chrome, Safari, Edge, Opera */
.rates-box > input::-webkit-outer-spin-button,
.rates-box > input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  /* -moz-appearance: textfield; */
}

.fac-location-check {
    display: flex;
    align-items: center;
    /* flex-direction: column; */
}

.facility-details input {
    /* margin-left: 10px; */
    width: 360px;
    padding-left: 10px;
    font-size: 13px;
}

.fac-location-check p {
    font-size: 12px;
}

.outdoor-details input {
    /* margin-left: 10px; */
    padding-left: 10px;
    width: 360px;
}

.outdoor-details {
    margin-bottom: 10px;
    font-size: 13px;

}