.image-carousel {

    border-radius: 10px;
}

.image-gallery {
    border-radius: 10px;
    /* border: 2px solid grey; */
}

.image-gallery-content.fullscreen {
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    height: 100%;
}