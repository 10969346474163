.client-thumbnail {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* cursor: pointer; */
    box-shadow: 3px 3px 10px -1px gray;
    padding: 20px 10px;
    transition: 100ms ease-in-out;
    width: 32%;
    margin-bottom: 40px;
}

/* .client-thumbnail:hover {
    background-color: rgb(245, 245, 245);
} */

/* .client-thumbnail:active {
    transform: scale(1.1);
} */

.ct-top {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ct-top img {
    height: 7vw;
    width: 7vw;
    border-radius: 50%;
}

.ct-bottom {
    font-size: 20px;
    margin-top: 10px;
}

.ct-actions {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 20px;
}

.ct-action {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    flex: 0.3;
    border-radius: 10px;
    padding: 6px;
    text-align: center;
    border: 1px solid #dddddd66;
    transition: 200ms ease-in-out;
    background-color: #dddddd66;

}

.ct-action:hover {
    /* transform: scale(1.05); */
    background-color: #dddddd;

}

.ct-action:active {
    transform: scale(1.05);
}

.ct-action p {
    font-size: 14px;
    margin-top: 5px;
}