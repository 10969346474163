.report-review-modal {
    z-index: 999;
    background-color: rgba(0,0,0,0.7); /* Black w/ opacity */
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.report-review-modal-content {
    border: 1px solid grey;
    width: max-content;
    height: max-content;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    color: white;
    background-color: rgba(0,0,0,0.95); /* Black w/ opacity */
}

.report-review-header-row {
    margin-bottom: 20px;
}

.report-review-modal-content p {
    margin-bottom: 10px;
}

.report-textarea {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.report-textarea textarea {
    /* height: 0px; */
    margin-left: 12px;
    font-size: 16px;
    width: 92%;
    transition: 200ms ease-in;
}

.report-button-row {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.report-button-row button:hover {
    background-color: transparent !important;
}

.report-review-modal-content .MuiCheckbox-root {
    color: white;
}