.credits-recon {
    min-height: calc(100vh - 141px);
}

.cr-header {
    padding: 20px
}

.credits-overview {
    padding: 20px;
}

.credits-overview h3 {
    width: 20vw;
}

.co-item {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.fixed-pee {
    width: 20vw;
}

.co-item p {
    font-size: 18px;
}

.credits-purchases {
    padding: 20px;
}