.document-uploader {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.document-uploader span {
    opacity: 0.5;
    margin-top: 5px;
    font-size: 15px;
}

.d-u-container {
    display: flex;
    margin-top: 20px;
}

.d-u-left {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    border: 2px solid #dddddd;
    border-radius: 8px;
    padding: 20px;
}

.ic-upload-icon {
    fill: #dddddd !important;
    font-size: 120px !important;

}

.d-u-options {
    display: flex;
    margin-top: 10px;
    /* align-items: center; */
}

.d-u-options input {
    display: none;
}

.d-u-options button {
    margin-left: 10px;
    background-color: black;
    border: 1px solid black;
    color: white;
    border-radius: 5px;
    padding: 5px 20px;
    transition: 200ms ease-in transform;
}

.d-u-options button:hover {
    cursor: pointer;
    transform: scale(1.03);
}

.du-id-container {
    display: flex;
    align-items: center;
}

.d-u-right {
    display: flex;
    /* align-items: center; */
    margin-left: 20px;
}

.du-id-container img {
    width: 100px;
    height: auto;
    border-radius: 5px;
    margin-right: 40px;
}

.du-cert-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-evenly;
}

.du-cert-item {
    display: flex;
    align-items: center;

}

.du-cert-item span {
    margin-top: 0;
    margin-left: 15px;
    transition: 200ms ease-in transform;
}

.du-cert-item span:hover {
    cursor: pointer;;
    transform: scale(1.5);
}

.du-cert-item span:active {
    opacity: 0.2;
}


.du-cert-item img {
    width: 20px;
    height: auto;
    margin-right: 10px;
}

.du-cert-item p {
    width: 60%;
    overflow: hidden;
}

