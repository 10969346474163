.calendar-info {
    z-index: 999;
    background-color: rgba(0,0,0,0.7); /* Black w/ opacity */
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.calendar-info-content {
    width: max-content;
    height: max-content;
    padding: 60px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    color: black;
    background-color: white; /* Black w/ opacity */
}