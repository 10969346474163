.notification-snack {
    display: flex;
    background-color: white;
    color: black;
    align-items: center;
    border-bottom: 2px solid gray;
    height: 10vh;
    width: 25vw;
    position: fixed;
    top: 20px;
    right: 20px;

}

.notification-snack:hover {
    cursor: pointer;
    background-color: rgb(233, 233, 233);
}

.notification-image {
transform: scale(1.5);
flex: 0.2;
display: flex;
align-items: center;
justify-content: center;
}

.notification-content {
    display: flex;
    flex-direction: column;
    flex: 0.8;
}

.notification-content h3 {
    margin-bottom: 2px;
    margin-top: 5px;
}

.notification-content p {
    font-size: 14px;
}

.notification-exit {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
}

.notification-exit p {
    font-size: 25px;
    margin-right: 5px;
    transition: 200ms ease-in;
}

.notification-exit p:hover {
    transform: scale(1.2);
}
