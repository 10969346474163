.chat-item {
    display: flex;
    background-color: white;
    color: black;
    align-items: center;
    border-bottom: 2px solid rgb(241, 235, 235);
    height: 10vh;
    width: 25vw;
}

.unread-chat-item {
    background-color: rgb(34, 34, 34) !important;
}

.unread-chat-item:hover {
    background-color: gray !important;
}

.chat-item:hover {
    cursor: pointer;
    background-color: rgb(233, 233, 233);
}

.chat-image {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0.2;
    margin-right: 10px;
}

.chat-image img {
    border-radius: 50%;
    height: 7vh !important;
    width: 7vh !important;
}

.chat-content {
    flex: 0.8;
    display: flex;
    flex-direction: column;
}

.unread-chat-content {
    color: white !important;
}

.chat-content h3 {
    margin-bottom: 2px;
    margin-top: 5px;
}

.chat-content p {
    font-size: 14px;
    max-width: 18vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.chat-content span {
    font-size: 12px;
    opacity: 0.5;
    margin-top: 2px;
}