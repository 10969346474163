.instructor-view-session-modal {
    z-index: 999;
    background-color: rgba(0,0,0,0.7); /* Black w/ opacity */
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    padding: 20px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.instructor-view-session-modal-content {
    box-shadow: 3px 3px 10px -1px black;
    border-radius: 3px;
    width: max-content;
    height: 45vh;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    color: black;
    width: 40vw;
    background-color: white; /* Black w/ opacity */
}

.session-info-row {
    display: flex;
    align-items: center;

}

.session-info-row .bold-text {
    margin-right: 10px;
}

.ivm-button-row  {
    justify-content: flex-end !important;
}

.ivm-button-row .cancel-button {
    margin-right: 50px;
}