.search-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: calc(100vh - 141px);
}

.search-page h1 {
    margin-top: 20px;
}

.search-filters {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;
    width: 90%;
    border: 2px solid black;
    box-shadow: 3px 3px 10px -1px gray;
    margin-bottom: 40px;
    padding: 20px;
}

.search-filters h2 {
    margin-bottom: 10px;
}

.filter-row {
    display: flex;
    width: 100%;
    /* align-items: center; */
    justify-content: space-evenly;
    margin-bottom: 20px;
}

.filter-row p {
    margin-bottom: 10px;
}

.filter-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0.3 !important;
    max-width: 30%;
    /* height: 90px; */
}

.slider-group p {
    margin-bottom: 22px;
    /* align-items: center !important; */
    /* height: 100% !important; */
    /* justify-content: space-between !important; */
    /* height: 86px; */
}

.search-row {
    display: flex;
    align-items: center;
    width: 100%;
}

.search-row p {
    min-width: 20%;
    /* flex: 0.2; */
}

.search-row-button {
    color: white;
    background-color: #023e73;
    border: 1px solid #023e73;
    padding: 7px 20px 7px 20px;
    margin-left: 50px;
    font-size: 20px;
    border-radius: 5px;
    transition: 200ms ease-in;
}

.search-row-button:hover {
    cursor: pointer;
    color: #023e73;
    background-color: white;
}

.PrivateValueLabel-circle-4 {
    background-color: #023e73 !important;
}

.MuiSlider-thumb {
    background-color: #023e73 !important;
}

.chip {
    color: white !important;
    background-color: #023e73 !important;
    margin-bottom: 0px !important;
    margin-top: 5px !important;
}

#search_input {
    height: 0px !important;
}
 .searchWrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    overflow: scroll;
    /* min-height: 50px !important; */
    min-height: 45px;
}

.hide {
    display: none !important;
}

.show {
    display: block !important;
}

.search-page .singleSelect {
    flex-wrap: nowrap;
}

.filter-button-row {
    margin-top: 20px;
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 20px;
}

.filter-button-row button {
    font-size: 20px;
    flex: 0.3;
    color: white;
    padding: 8px 0px 8px 0px;
    border-radius: 7px;
}

.filter-button-row button:hover {
    cursor: pointer;
    background-color: transparent;
}

.clear-button {
    background-color: #D94A4A;
    border: 1px solid #D94A4A;
}

.clear-button:hover {
    color: #D94A4A;
}

.search-button {
    background-color: #023e73;
    border: 1px solid #023e73;
}

.search-button:hover {
    color:#023e73;
}

.search-results {
    display: flex;
    align-items: center;
    /* flex-wrap: wrap; */
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    /* padding: 0% 5%; */
}

.optionContainer li {
    display: flex;
    align-items: center;
}

.no-results-container {
    /* border: 1px solid lightgray; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.no-results-container h1 {
    padding: 100px 0px 100px 0px;
}

.MuiSlider-root {
    color: black !important;
}

.select-search__select {
    z-index: 500 !important;
}

.fac-search {
    width: 100%;
}