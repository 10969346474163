.notification-drop {
    top: 80px;
    right: 0vw;
    position: absolute;
    z-index: 980;
    background-color: silver;
    color: white;
    border: 2px solid silver;
    border-top: none !important;
    border-right: none;
    opacity: 1;
    /* padding: 5px 5px 10px 10px; */
    transition: 1s ease-in;
    box-shadow: 3px 3px 10px -1px gray;

}

.mark-read {
    display: flex;
    justify-content: flex-end;
    padding: 5px 10px 5px 0px;
    background-color: white;
    color: black;
    font-weight: bold;
    font-size: 14px;
    border-bottom: 2px solid grey;
    cursor: pointer;
}

.mark-read:hover {
    background-color: lightgray;
}



@media all and (min-width: 1024px) and (max-width: 1280px) { }

@media all and (min-width: 768px) and (max-width: 1024px) {
    .notification-item {
        width: 30vw !important;
    }
 }

@media all and (min-width: 480px) and (max-width: 768px) {
    .notification-drop {
        right: auto;
        width: 100vw;
        height: 40vh !important;
        overflow: scroll;
    }

    .notification-item {
        width: 100% !important;
    }
 }

@media all and (max-width: 480px) {

    .notification-drop {
        right: auto;
        width: 100vw;
        height: 40vh !important;
        overflow: scroll;
    }

    .notification-item {
        width: 100% !important;
    }

}