.bulk-discount {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.bulk-discount h3 {
    opacity: 0.5;
}

.amount-input {
    width: 10vw;
    height: 55px !important;
    box-shadow: 3px 3px 10px -1px gray;
    margin-left: 10px;
    /* margin-right: 10px; */
}

.perc-input {
    width: 10vw;
    height: 55px !important;
    box-shadow: 3px 3px 10px -1px gray;
    margin-right: 10px;
    margin-left: 20px;
}

.bulk-discount p {
    margin-right: 20px;
}

.bulk-discount .MuiSvgIcon-root {
    cursor: pointer;
    /* transform: scale(1.3); */
}

.bulk-discount .MuiSvgIcon-root:active {
    transform: scale(1.4);

}

.bulk-discount .select-search {
    border: none;
    box-shadow: 3px 3px 10px -1px gray;
    margin-right: 40px;

}

.bulk-discount .select-search__input {
    height: 55px !important;
}