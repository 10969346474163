.owner-annual-statement {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: calc(100vh - 144px);
    margin-top: 20px;
}

.oas-header-row {
    display: flex;
    justify-content: space-between;
    width: 100%
}

.oas-header-row .select-search {
    width: 15vw !important;
}

.oas-table-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0px;
    box-shadow: 3px 3px 10px -1px gray;
}

.oas-table-container table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    border-radius: 10px;
}

.oas-table-container table tr:last-child {
    font-weight: bold !important;
    background-color: #033d73 !important;
    color: white;
}

.oas-table-container td {
    text-align: center;
}

/* .oas-table-container tr:nth-child(even){background-color: #dddddd;} */

.grey-row {
    background-color: #dddddd !important;
}

.month-row th {
    padding: 10px;
}

.oas-table-container tr {
    background-color: #f8f8f8;

}

.oas-table-container tr td:last-child {
    font-weight: bold !important;
    background-color: #033d73;
    color: white;
}

.main-td {
    width: 100% !important;
    font-weight: bold;
    font-size: 20px;

}

.start-item {
    text-align: start !important;
    /* width: max-content !important; */
    padding: 10px;
    width: 10vw !important;

}