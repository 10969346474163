.completion-modal {
    z-index: 999;
    background-color: rgba(0,0,0,0.7); /* Black w/ opacity */
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.completion-modal-content {
    border: 1px solid grey;
    border-radius: 3px;
    width: max-content;
    height: max-content;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: black;
    background-color: white;
}

.done-icon {
    color: green;
}

.close-icon {
    color: #D94A4A;
}

.cm-container {
    display: flex;
    flex-direction: column;
}

.cm-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.completion-modal-content button {
    margin-top: 20px;
    background-color: black;
    color: white;
    padding: 7px 35px;
    text-transform: uppercase;
    border-radius: 3px;
    border: 1px solid black;
    cursor: pointer;
    transition: 200ms ease-in-out;
}

.completion-modal-content button:active {
    transform: scale(1.05);
}
