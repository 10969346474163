.contact {
    padding: 4px 0 4px 0;
    background-color: black;
    color: white;
    display: flex;
    flex-direction: column;
    margin-bottom: 2px;
}

.contact > p {
    margin-left: 7%;
    /* font-weight: bold; */
    font-size: 18px;
    cursor: pointer;
    transition: 200ms ease-in;

}

.contact > p:hover {
    color: #D94A4A;
}

.contact > hr {
    margin-left: 5%;
    margin-top: 5px;
    height: 1px;
    width: 90%;
    color: grey;
    background-color: grey;
}

.active {
    color: #D94A4A;
}

/* ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0px;
  } */