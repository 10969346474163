.search-item {
    display: flex;
    height: max-content;
    width: 90%;
    box-shadow: 3px 3px 10px -1px gray;
    border: 1px solid lightgray;
    margin-bottom: 50px;
}

.search-item h3 {
    text-decoration: underline;
}

.search-item hr {
    width: 40%;
    margin-top: 15px;
}

.search-item-left {
    padding: 20px;
    flex: 0.4;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.search-item-right {
    flex: 0.6;
    display: flex;
    flex-direction: column;
    padding-left: 20px;

}

.search-header {
    /* margin-top: 20px; */
    display: flex;
    flex-direction: column;
    width: 100%;
    /* align-items: center; */
    /* justify-content: center; */
}

.search-header h1 {
    margin-top: 0;
}

.search-header p {
    opacity: 0.7;
}

.search-content {
    display: flex;
    justify-content: space-between;
}

.search-content-right {
    flex: 0.5;
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.search-content-left {
    flex: 0.5;
    display: flex;
    flex-direction: column;
    padding-top: 15px;
}

.rate-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.rate-container h3 {
    margin-bottom: 5px;
}

.rate-item {
    display: flex;
}

.rate-item p {
    margin-left: 5px;
}

.search-content-review {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.search-content-review-item {
    display: flex;
    align-items: center;
}

.search-content-review h3 {
    margin-bottom: 5px;
}

.search-content-review-item p {
    margin-left: 10px;
    margin-bottom: 1px;
}

.search-item-button-row {
    width: 100% !important;
    margin-bottom: 40px;
    justify-content: space-between !important;
    display: flex;
    align-items: center;
}

.search-content-review-item h4 {
    text-transform: uppercase;
    opacity: 0.5;
}

.sibr-left {
    width: 100%;
}

.sibr-right {
    display: flex;
    justify-content: flex-end !important;
    padding-right: 20px;
}

/* .view-button {
    background-color: black !important;
    border-color: black !important;
}

.view-button:hover {
    background-color: transparent !important;
    color: black !important;
} */

.search-item-button-row button {
    flex: 0.2 !important;
    /* margin-right: 20px; */
}

.search-content-description h3 {
    margin-bottom: 5px;
}

.si-header-container {
    display: flex;
    align-items: center;
}

.si-header-container h1 {
    font-size: 30px;
}

.si-header-review {
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.si-header-review p {
    margin-left: 5px;
    font-size: 17px;
}

.facilities-container {
    margin-top: 5px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.amenities-container {
    display: flex;
    align-items: center;
}

.info-icon {
    margin-right: 5px;
}

.favorites-button-row {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px;
    transition: 100ms ease-in;
}

.favorites-button-row p {
    margin-right: 10px;
    opacity: 0.7;
}

.favorites-button-row .MuiSvgIcon-root {
    fill: #D94A4A !important;
}

.favorites-button-row:hover {
    cursor: pointer;
    /* transform: scale(1.01); */
    opacity: 0.5;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
    max-height: 10vh;
}

.search-item .image-gallery-content.fullscreen .image-gallery-slide .image-gallery-image {
    height: auto !important;
}

.search-item .image-gallery-content .image-gallery-slide .image-gallery-image {
    height: 30vh !important;
    width: 30vw !important;
    object-fit: cover !important;
}

.location-pee {
    letter-spacing: 0.75px;
    text-transform: uppercase;
    margin: 2.5px 0px 0px 0px;
}

.si-header-review > .MuiSvgIcon-root {
    fill: #D94A4A;
}

.search-item h5 {
    margin-left: 5px;
}

.distance-display {
    opacity: 0.5;
    display: flex;
    align-items: center;
}