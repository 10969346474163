.signup-form {
    background-color: #033d73;
    height: max-content;
    width: 35vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    box-shadow: 1px 7px 10px #033d73;
}

.signup-form img {
    margin-top: 10px;
    height: 7vh;
    width: auto;
    object-fit: contain;
}

.css-blqqkv-MuiCircularProgress-root {
    color: white !important;
}

.half {
    /* width: 60%; */
}

.password-hint {
    font-size: small;
    margin-left: 10px;
}

.go-to-login-row p {
    color: white;
    text-decoration: underline;
    margin-bottom: 15px;
}

.go-to-login-row p:hover {
    cursor: pointer;
    color: #D94A4A;
}

.signup-button-row button {
    font-weight: bold;
    color: rgb(207, 204, 204);
    border: none;
    background-color: #D94A4A;
    border-radius: 2px;
    font-size: 20px;
    padding: 7px 30px 7px 30px;
    border: 1px solid #D94A4A;
    margin-bottom: 15px;
    margin-top: 20px;
}

.signup-button-row button:hover {
    cursor: pointer;
    color: #D94A4A;
    background-color: black;
}

.signup-button-row button:active {
    background-color: white;
}

.signup-button-row button:disabled {
    background-color: grey;
    color: white;
    border-color: grey;
}

.signup-error-container {
    color: #D94A4A;
    padding: 10px;
    text-align: center;
}

.signup-error-container p {
    transition: ease-in;
}

.fade-in {
	animation: fadeIn 0.3s;
  	opacity: 1;
}

@keyframes fadeIn {
  from {
  	opacity: 0;
  }
  to {
 	opacity: 1;
  }
}

.role-selection-row {
    display: flex;
    color: white !important;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    fill: white !important;
    margin-top: 20px;
}

.role-selection-item {
}

.role-selection-item .MuiSvgIcon-root {
    fill: white !important;
}

.signup-form .login-input-container {
    margin-top: 20px !important;
}