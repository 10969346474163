.home-booking-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 10px;
    height: 90px;
    box-shadow: 3px 3px 10px -1px gray;
    margin-bottom: 10px;
    cursor: pointer;
    transition: 100ms ease-in-out;
}

.home-booking-item:active {
    transform: scale(1.05);
}

.home-booking-item:hover {
    cursor: pointer;
    background-color: rgb(233, 233, 233);
}


.hourly-item {
    display: flex;
    align-items: center;
}

.hourly-item-left {
    flex: 0.7;
    display: flex;
    flex-direction: column;
}

.hourly-item-left p {
    font-size: 14px;
}

.hourly-item-right {
    flex: 0.3;
}

.hourly-item-right p {
    font-size: 14px;
}

.home-booking-item h1 {
    margin-bottom: 5px;
    font-size: 18px;
}

.home-booking-item p {
    margin-bottom: 3px;
}

