.info-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: black;
    width: 15vw;
    /* height: 10vh; */
    margin: 20px 10px 0px 10px;
    border-radius: 3px;
    /* margin-top: 20px; */
    box-shadow: 1px 4px 2px -2px gray;
}

.info-card-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
}

.icon-container {
    flex: 0.2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    transition-duration: 500ms;
}

.icon-container:hover {
    transform: rotate(-45deg);
}

.info-card-content-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    padding: 10px;
    flex: 0.8;
}

.info-card-content-container h2 {
    font-size: 25px;
    margin-bottom: 15px;

}

.info-card-content-container p {
    font-size: 14px;
    /* width: 70%; */
}

.info-card-container .MuiSvgIcon-root {
    font-size: 60px;
    opacity: 0.4 !important;
}

.info-card-bottom-row {
    display: flex;
    align-items: center !important;
    justify-content: center;
    background-color: #ffffff4D;
    padding: 3px 10px 3px 10px;
}

.MuiSvgIcon-root {
    vertical-align: middle;
    transition-duration: 500ms !important;
    /* transition-timing-function: ease-in; */
}


.info-card-bottom-row p {
    font-size: 13px;
    /* color: #ffffffCC; */
    font-weight: bold;
}
