.available-modal {
    z-index: 999;
    background-color: rgba(0,0,0,0.7); /* Black w/ opacity */
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.available-modal-content {
    box-shadow: 3px 3px 10px -1px black;
    border-radius: 3px;
    width: max-content;
    height: 45vh;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    color: black;
    background-color: white; /* Black w/ opacity */
}

.available-modal input {
    border: none;
    box-shadow: 3px 3px 10px -1px gray;

}

.available-modal-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.available-modal-row h1 {
    margin: 0px 10px;
}

.available-modal .button-row {
    justify-content: flex-end !important;
}

.available-modal .cancel-button {
    /* background-color: white !important;
    color: black !important;
    border-color: white !important; */
}

.available-modal .cancel-button:hover {
    background-color: transparent !important;
    /* color: white !important; */
}

.available-modal .confirm-button {
    margin-right: 20px;
}

.available-modal #confirm-button:hover {
    background-color: transparent !important;
}

.available-modal p {
    font-size: 18px;
    margin-right: 50px;

}

.availability-error-message-row p {
    color: #D94A4A;
}

.middle-pee {
    flex: 1;
}

.bold {
    font-weight: bold;
    flex: 0.2;
}

.available-dt-picker {
    /* background-color: white !important; */
    /* width: 100px */
    /* font-size: 18px; */
}

.available-dt-picker input p {
    color: white !important;

}

.react-datetime-picker {
    color: black;
}

input.react-datetime-picker__inputGroup__input.react-datetime-picker__inputGroup__day {
    color: white
}

input.react-datetime-picker__inputGroup__input.react-datetime-picker__inputGroup__day {
    color: white !important;
}

.react-datetime-picker__inputGroup__input {
    color: white;
}

.react-datetime-picker__button {
    color: white !important;
}

.react-datetime-picker__inputGroup__divider {
    color: white;
}
.react-datetime-picker *:after {
    color: white;
}

.react-datetime-picker__inputGroup__leadingZero {
    color: white !important;
}

.react-datetime-picker__button__icon {
    color: white !important;
}

.available-options-container {
    display: flex;
    width: 100%;
    flex-direction: column;

}

.available-options-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.available-options-group input {
    background-color: white;
    color: black;
    padding: 5px;
    width: 100px;
    border: none;
    box-shadow: 3px 3px 10px -1px gray;

}

.available-options-group p {
    margin: 0;
}

.available-modal-content .react-time-picker__wrapper {
    background-color: white !important;
    width: 200px !important;
}

.available-modal-content .date-row input {
    width: 200px !important;
}

@media all and (min-width: 1024px) and (max-width: 1280px) { }

@media all and (min-width: 768px) and (max-width: 1024px) { }

@media all and (min-width: 480px) and (max-width: 768px) {

    .available-modal-content {
        flex-direction: column !important;
        height: max-content;
        width: 90vw;
    }

    .available-modal-content h1 {
        font-size: 20px;
        text-align: center;
    }

    .available-modal-row {
        /* flex-direction: column; */
    }

    .available-modal-row p {
        margin-right: 0px;
        margin: 20px 0px;
    }

    .available-options-container {
        margin: 30px 0px;
        align-items: center;
    }

    .available-options-group {
        width: 100%;
        justify-content: space-between;
    }

    .available-options-header {
    width: 70%;
    }

    .available-modal-content .button-row {
        justify-content: space-evenly !important;
    }


 }

@media all and (max-width: 480px) {
.available-modal-content {
    flex-direction: column !important;
    height: max-content;
    width: 90vw;
}

.available-modal-content h1 {
    font-size: 20px;
    text-align: center;
}

.available-modal-row {
    flex-direction: column;
}

.available-modal-row p {
    margin-right: 0px;
    margin: 20px 0px;
}

.available-options-container {
    margin: 30px 0px;
    align-items: center;
}

.available-options-group {
    width: 100%;
    justify-content: space-between;
}

.available-options-header {
width: 70%;
}

.available-modal-content .button-row {
    justify-content: space-evenly !important;
}

}