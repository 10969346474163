.forgot-password-modal {
    z-index: 999;
    background-color: rgba(0,0,0,0.7); /* Black w/ opacity */
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.forgot-password-modal-content {
    border: 1px solid grey;
    border-radius: 3px;
    width: max-content;
    height: max-content;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    color: black;
    background-color: white; /* Black w/ opacity */
}

.forgot-password-modal-content h1 {
    margin-bottom: 10px;
}

.forgot-password-modal-content input {
    border: none;
    box-shadow: 3px 3px 10px -1px gray;
    width: 50%;
    margin: 20px 0px;
}

.forgot-password-modal-content .general-button:hover {
    background-color: transparent;
    color: black;
}

.forgot-password-modal-content .general-button:disabled {
    background-color: grey !important;
    border-color: grey !important;
    cursor: inherit;
}

.forgot-password-modal-content .general-button:disabled:hover {
    color: white;
}

.forgot-password-modal-content .error-message {
    color: #D94A4A;
    margin: 10px 0px 20px 0px;
}