.bookings {
    padding-left: 40px;
    padding-top: 20px;
    padding-right: 40px;
    display: flex;
    justify-content: space-between;
    min-height: calc(100vh - 141px);
    width: 100%;
}

.bookings-col-left h3 {
    font-size: 140%;
    text-decoration: underline;
    text-underline-offset: 2px;
}

.bookings-col-left {
    display: flex;
    flex-direction: column;
}

.bookings-months {
    display: flex;
    align-items: center;
}

.bookings-header-row .select-search__input {
    background-color: #033d73 !important;
    color: white !important;
}

.bookings-header-row .select-search__value::after {
    border-color: white !important;
}


.bookings-col-right {
    display: flex;
    padding-top: 61px;
}

.confirmed-bookings-header {
    margin-top: 20px;
    margin-bottom: 20px;

}

.right-sel-search {
    margin-left: 20px;
}

.confirmed-bookings {
    width: 60vw;

}

.pending-bookings {
    margin-bottom: 20px;
    width: 60vw;
}

.pending-bookings h1 {
    margin-top: 20px;
}

.bookings-header-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* width: 60vw; */
}

.bookings-header-row .select-search {
    width: auto !important;
}

.classes-overview {
    display: flex;
    flex-direction: column;
}

.new-class {
    padding: 10px 20px !important;
    margin-bottom: 20px;
    margin-top: 10px;
}


@media all and (min-width: 1024px) and (max-width: 1280px) { }

@media all and (min-width: 768px) and (max-width: 1024px) {

    .pending-bookings {
        width: auto;
    }

    .bookings-col-left {
        width: 50vw !important;
    }

    .bookings-header-row {
        flex-direction: column;
    }

    .bookings-months {
        margin-top: 10px;
    }
 }

@media all and (min-width: 480px) and (max-width: 768px) {

    .bookings-header-row {
        flex-direction: column;
    }

    .bookings-months {
        margin-top: 10px;
    }

    .booking-details-div {
        flex-direction: column;
    }

    .vb-left-col {
        justify-content: center;
    }

    .vb-right-col {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .revenue-div {
        width: 100% !important;
        justify-content: center !important;
    }

    .revenue-left {
        margin-right: 20px;
    }

    .vb-left-col img {
        height: 20vw !important;
        width: auto !important;
        border-radius: 50% !important;
    }
    .bookings {
        padding: 20px;
        justify-content: flex-start !important;
        /* text-align: center; */
        text-align: center;
        flex-direction: column-reverse;
    }

    .pending-bookings {
        width: 80vw !important;
    }



    .confirmed-bookings h1 {
        /* text-align: center !important; */
    }

    .bookings-col-left {
        align-items: center;
        justify-content: flex-start !important;
        width: 100%;
    }
    .confirmed-bookings {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80vw;
    }

    .confirmed-booking-item {
        width: 100% !important;
        /* flex-direction: column; */
    }

    .cb-left-col > img {
        width: 100%;
    }

    .pb-right-col {
        align-items: center;
    }

    .pb-text-right, .pb-text-left {
        /* text-align: center; */
    }

    .pb-button-row {
        justify-content: center !important;
    }

    .bookings-col-right {
        justify-content: center;
        padding-top: 0px;
    }

    .bookings-header-row .select-search {
        width: 30vw !important;
    }

    .bookings-header-row {
        width: 80vw !important;
        /* flex-direction: column !important; */
    }
}

@media all and (max-width: 480px) {

    .booking-details-div {
        flex-direction: column;
    }

    .vb-left-col {
        justify-content: center;
    }

    .vb-right-col {
        display: flex;
        flex-direction: column;
        align-items: center;

    }

    .revenue-div {
        width: 100% !important;
        justify-content: center !important;
    }

    .revenue-left {
        margin-right: 20px;
    }

    .vb-left-col img {
        height: 20vw !important;
        width: auto !important;
        border-radius: 50% !important;
    }

    .bookings {
        /* padding: 20px; */
        justify-content: flex-start !important;
        text-align: center;
        flex-direction: column-reverse;

    }

    .bookings-col-left {
        align-items: center;
        justify-content: flex-start !important;
        margin-top: 40px;
    }

    .selected-client-menu {
        justify-content: center;
    }

    .confirmed-bookings {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80vw;
    }

    .confirmed-booking-item {
        width: 100% !important;
        flex-direction: column;
    }

    .pending-booking-item {
        /* width: 100% !important; */
        flex-direction: column;
    }

    .pb-right-col {
        align-items: center;
    }

    .pb-text-right, .pb-text-left {
        text-align: center;
    }

    .pb-button-row {
        justify-content: center !important;
        width: 90% !important;
    }

    .pb-confirm-button {
        margin: 0px 40px;
    }

    .pb-button-row button {
       width: 20vw !important;
       /* margin-right: 0px !important; */
       /* margin: auto; */
    }
    .cb-button-row button {
       width: 20vw !important;
       /* margin-right: 0px !important; */
       /* margin: auto; */
    }

    .bookings-col-right {
        justify-content: center;
        padding-top: 0px;
    }

    .pending-bookings {
        width: 80vw;
    }
    .confirmed-bookings {
        width: 80vw;
    }

    .pb-info-group {
        padding-left: 20px;
    }

    .cb-info-group {
        padding-left: 20px;
    }

    .bookings .selected-client-menu {
        margin-top: 20px !important;
        width: 100% !important;
    }
 }