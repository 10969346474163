.notification-item {
    display: flex;
    background-color: white;
    color: black;
    align-items: center;
    border-bottom: 2px solid gray;
    height: max-content;
    width: 25vw;

}

.notification-item:hover {
    cursor: pointer;
    background-color: rgb(233, 233, 233);
}

.notification-image {
transform: scale(1.5);
flex: 0.2;
display: flex;
align-items: center;
justify-content: center;
}

.notification-content {
    display: flex;
    flex-direction: column;
    flex: 0.8;
}

.notification-content h3 {
    margin-bottom: 2px;
    margin-top: 5px;
}

.notification-content > p {
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 20vw;
}

.unread {
    background-color: lightgray !important;
}

.notification-date {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    opacity: 0.6;
    padding: 5px;
}

.notification-date p {
    font-size: 70% !important;
}