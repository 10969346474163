.booking-confirmation {
    display: flex;
    flex-direction: column;
    width: 80vw;
    margin-bottom: 20px;

}

.booking-confirmation > h3 {
    margin-bottom: 40px;
}

.booking-details-div {
    display: flex;
    /* border: 2px solid silver; */
    border-radius: 4px;
    box-shadow: 3px 3px 10px -1px gray;

}

.bc-left-col {
    flex: 0.4;
    padding: 20px;
    display: flex;
    align-items: center;
    width: 100%;
}

.bc-left-col img {
    object-fit: fill;
}

.bc-right-col {
    flex: 0.6;
    padding: 10px;
    border-radius: 5px;
}


.booking-details-div img {
    /* height: 120px; */
    width: 100%;
    height: auto;
    border-radius: 4px;
}

.revenue-div {
    display: flex;
    justify-content: space-between;
    width: 50%;
}

.revenue-div p {
    margin-bottom: 5px;
}

.net-revenue {
    font-weight: bold;
    text-decoration: underline;

}

.bc-button-row {
    margin-top: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.button-left {
    flex: 0.2;
}

.bc-button-row button {
    width: 80px;
    color: white;
    padding: 8px;
    border-radius: 3px;
    font-size: 14px;
    font-weight: bold;
}

.bc-button-row button:hover {
    cursor: pointer;
}

.bc-view-button {
    background-color: black;
    border: 1px solid black;
}
.bc-view-button:hover {
    background-color: white;
    color: black;
}

.bc-deny-button {
    background-color: #D94A4A;
    border: 1px solid #D94A4A;
}

.bc-deny-button:hover {
    background-color: white;
    color: #D94A4A;
}

.bc-confirm-button {
    background-color:#023e73;
    border: 1px solid #023e73;
    margin-left: 40px;
}

.bc-confirm-button:hover {
    background-color: white;
    color: #023e73;
}