.schedule-class-modal {
    z-index: 999;
    background-color: rgba(0,0,0,0.7); /* Black w/ opacity */
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.schedule-class-modal-content {
    border: 1px solid grey;
    border-radius: 3px;
    width: max-content;
    height: max-content;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    color: black;
    background-color: white;
}

.schedule-class-modal-content h2 {
    margin-bottom: 10px;
}

.schedule-class-modal-content h4 {
    margin-bottom: 20px;
}

.date-time-container {
    display: flex;
    /* align-items: center; */
    flex-direction: column;
}

.date-time-group {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.date-time-group input {
    box-shadow: 3px 3px 10px -1px gray;
    border: none;
    margin-left: 20px;
    width: 100%;
}

.error-message-row {
    color: #D94A4A;
    margin: 5px 0px;
}