.menu-item {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

 .menu-item p {
    padding: 10px 10px 10px 0;
    font-size: 18px;
    transition: 300ms ease-in;

}

.menu-item p:hover {
    color: #D94A4A;
    cursor: pointer;
}

.menu-item > hr {
    width: 85%;
}

.active {
    color:red;
}