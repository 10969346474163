.broadcast-message-modal {
    z-index: 999;
    background-color: rgba(0,0,0,0.7); /* Black w/ opacity */
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.broadcast-message-modal-content {
    box-shadow: 3px 3px 10px -1px black;
    border-radius: 3px;
    width: max-content;
    min-width: 50vw;
    height: 60vh;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    color: black;
    background-color: white; /* Black w/ opacity */
}

.broadcast-message-modal-content p {
    opacity: 0.5;
}

.optionContainer li {
    background-color: white !important;
    color: black;
}

.optionContainer li:hover {
    background-color: black !important;
}

.chip {
    background-color: #023e73 !important;
    font-size: 16px !important;
}

.broadcast-message-modal-content textarea {
    width: 100%;
    background-color: white;
    color: black;
    margin-bottom: 0px !important;
    border: none;
    box-shadow: 3px 3px 10px -1px gray;

}

.broadcast-button-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.broadcast-button-row button {
    color: white;
    border-radius: 5px;
    font-size: 18px;
    padding: 10px 40px 10px 40px;
    transition: 300ms ease-in;
}

.broadcast-send-button {
    background-color: #023e73;
    border-color: #023e73;
}

.broadcast-send-button:hover {
    background-color: transparent !important;
    color: #023e73;
    cursor: pointer;
}

.broadcast-close-button {
    background-color: #D94A4A;
    border-color: #D94A4A;
}

.broadcast-close-button:hover {
    color: #D94A4A;
    background-color: transparent !important;
    cursor: pointer;
}

.broadcast-error-row {
    transition: 200ms;
}

.broadcast-error-row p {
    color: #D94A4A;
    opacity: 1;
    margin-bottom: 10px;
    transition: 200ms;
}

.multiSelectContainer {
    border: none;
    box-shadow: 3px 3px 10px -1px gray;
}

.multiSelectContainer input {
    color: white;
    border: none;

}

.bmm-select-all {
    display: flex;
    align-items: center;
}

.bmm-select-all p {
    /* color: white !important; */
    opacity: 1;
}

.bmm-select-all .MuiSvgIcon-root {
    /* fill: white; */
}

.searchWrapper {
    background-color: white;
}

@media all and (min-width: 1024px) and (max-width: 1280px) { }

@media all and (min-width: 768px) and (max-width: 1024px) { }

@media all and (min-width: 480px) and (max-width: 768px) {
.broadcast-message-modal-content {

}

}

@media all and (max-width: 480px) {

    .broadcast-message-modal-content h1 {
        font-size: 20px;
    }

    .broadcast-button-row button {
        padding: 10px 20px;
        font-size: 14px;
    }

    .broadcast-message-modal-content {}

    .broadcast-button-row {
        justify-content: space-evenly !important;
    }

    .broadcast-message-modal-content textarea {
        /* height: 30vh; */
    }

 }