.reschedule-session-modal {
    z-index: 999;
    background-color: rgba(0,0,0,0.7); /* Black w/ opacity */
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.general-button {
    background-color: black;
    border-color: black;
    cursor: pointer;
    transition: 200ms ease-in-out;
}

.reschedule-session-modal-content {
    border: 1px solid grey;
    border-radius: 3px;
    width: max-content;
    height: max-content;
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    color: black;
    background-color: white; /* Black w/ opacity */
}

.reschedule-session-modal-content input {
    border: none;
    box-shadow: 3px 3px 10px -1px gray;
}

.rs-row {
    margin: 20px 0px 10px 0px;
}

.rs-row input {
    width: 100%;
}

.reschedule-session-modal .confirm-button {
    margin-left: 0px !important;
    /* margin-top: 20px; */
}

.reschedule-session-modal .date-row p {
    width: 50px !important;
    margin-right: 50px;
    flex: none !important;
}

.reschedule-session-modal .bold {
    flex: none !important;
}

.rs-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 70%;
}

.reschedule-session-modal .button-row {
    margin-top: 20px;
}

.reschedule-session-modal-content > p {
    margin-top: 10px;
}