.stats-box {
    display: flex;
    flex-direction: column;
    /* max-width: 100%; */
    align-items: center;
    box-shadow: 3px 3px 10px -1px gray;
    width: 100%;
    margin-bottom: 40px;
    min-height: 50vh;
    min-width: 50vw;
    transition: 200ms ease-in-out;

}

.stats-box-container {
    display: flex;
    align-items: center;
    /* flex-wrap: wrap; */
    flex-direction: column;
    padding: 20px;
    justify-content: space-evenly;

}

.stats-box-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.stats-settings-row {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding: 5px 5px 0px 0px;
    cursor: pointer;
}

.stats-box-box ._loading_overlay_overlay {
    background: transparent !important;
}

.stats-box-box ._loading_overlay_spinner {
    color: grey !important;
}

.stats-box-box .css-50etie svg circle {
    stroke: grey !important;
}