.review-item {
    display: flex;
    flex-direction: column;
    width: 90%;
}

.ri-header {
    display: flex;
    align-items: center;
}

.ri-header img {
    width: 5vw;
    height: auto;
    border-radius: 50%;
}

.ri-header h2 {
    margin-top: 0px !important;
    margin-left: 20px;
    font-size: 20px !important;
}

.review-item > span {
    opacity: 0.6;
    margin-top: 10px;
    font-size: 14px;
}

.review-item p {
    opacity: 0.8;
}