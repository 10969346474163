

@font-face {
  font-family: 'LibreFranklin';
  src: url(./assets/fonts/LF/LibreFranklin-Light.ttf)
}

@font-face {
  font-family: 'LibreFranklin-Bold';
  src: url(./assets/fonts//LF/LibreFranklin-SemiBold.ttf);
}

.css-1u1byk {
  background-color: pink !important;
}


* {
  margin: 0;
  font-family: 'LibreFranklin' !important;
  outline-width: 0;
  scroll-behavior: smooth;

}

button {
  cursor: pointer;
}

.picton-button {
  background-color: #50A7F2 !important;
  border-color: #50A7F2 !important;
  transition:  200ms ease-in-out !important;
  color: white !important;
}

.picton-button:hover {
  background-color: transparent !important;
  color: #50A7F2 !important;
}

.saffron-button {
  background-color: #F2C53D !important;
  border-color: #F2C53D !important;
  transition: 200ms ease-in-out;
}

.saffron-button:hover {
  background-color: transparent !important;
  color: #F2C53D !important;
}

.regal-button {
  background-color: #033d73 !important;
  border-color: #033d73 !important;
  transition: 200ms ease-in-out !important;
  color: white !important;
}

.regal-button:hover {
  background-color: transparent !important;
  color: #033d73 !important;
}

.valencia-button {
  background-color: #D94A4A !important;
  border-color: #D94A4A !important;
  transition: 200ms ease-in-out;
}

.valencia-button:hover {
  background-color: transparent !important;
  color: #D94A4A !important;
}

.image-gallery-icon:hover {
  color: #D94A4A !important;
}


p, h1, h2, h3, h4, h5 {
  font-family: 'LibreFranklin';
}

.req-asterisk {
  color: #D94A4A;
  /* vertical-align: top; */
  margin-left: 2px;
}

.red-border {
  border-color: #D94A4A;
  border-radius: 3px;
  text-decoration: none !important;
}

.bold-text {
  font-family: 'LibreFranklin-Bold';
}

.mb-20 {
  margin-bottom: 20px;
}
.mt-20 {
  margin-top: 20px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-10 {
  margin-top: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}