.add-client-modal {
    z-index: 999;
    background-color: rgba(0,0,0,0.7); /* Black w/ opacity */
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.add-client-modal-content {
    box-shadow: 3px 3px 10px -1px grey;
    border-radius: 3px;
    width: 50vw;
    height: max-content;
    padding: 30px;
    /* display: flex; */
    overflow: scroll;
    /* flex-direction: column; */
    margin: auto;
    /* align-items: flex-start; */
    /* justify-content: space-evenly; */
    color: black;
    background-color: white;
}

.acm-input-group {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin-bottom: 10px;
}

.acm-input-group input {
    box-shadow: 3px 3px 10px -1px gray;
    border: none;
    flex: 0.45;
}

.add-client-modal-content textarea {
    width: 80%;
    border: none;
    box-shadow: 3px 3px 10px -1px gray;
    border-radius: 7px;
    resize: none;

}

.add-client-modal-content h4 {
    margin-bottom: 10px;
    margin-top: 20px;

}


.acm-details-group {
    display: flex;
    width: 35%;
    align-items: center;
    margin-bottom: 10px;
}

.acm-details-group p {
    width: 20vw;
}

.acm-details-group input {
    box-shadow: 3px 3px 10px -1px gray;
    border: none;
    width: 5vw;
}

.add-client-modal .confirm-button {
    margin-left: 0px !important;
}

@media all and (min-width: 1024px) and (max-width: 1280px) {
    .add-client-modal-content {
        width: 70vw;
        height: 75vh;

    }
 }

@media all and (min-width: 768px) and (max-width: 1024px) {
    .add-client-modal-content {
        width: 90vw;
        height: 75vh;

    }

    .acm-details-group input {
        width: 10vw;
    }

    .add-client-modal-content .confirm-button {
        margin-left: 0px !important;
    }
 }

@media all and (min-width: 480px) and (max-width: 768px) {
    .add-client-modal-content {
        width: 90vw;
        height: 75vh;

    }

    .add-client-modal-content h1 {
        font-size: 20px;
    }

    .acm-input-group {
        flex-direction: column;
        margin-bottom: 0px;
    }

    .acm-input-group > input {
        margin-bottom: 10px;
    }

    .acm-details-group {
        width: max-content;
    }

    .acm-details-group input {
        width: 20vw;
    }

    .add-client-modal-content .confirm-button {
        margin-left: 0px !important;
    }
}

@media all and (max-width: 480px) {
    .add-client-modal-content {
        width: 90vw;
        height: 75vh;

    }

    .add-client-modal-content h1 {
        font-size: 20px;
    }

    .acm-input-group {
        flex-direction: column;
        margin-bottom: 0px;
    }

    .acm-input-group > input {
        margin-bottom: 10px;
    }

    .acm-details-group {
        width: max-content;
    }

    .acm-details-group input {
        width: 20vw;
    }

    .add-client-modal-content .confirm-button {
        margin-left: 0px !important;
    }
 }