.pending-booking-item {
    display: flex;
    margin-top: 20px;
    /* border: 2px solid grey; */
    padding: 5px;
    /* width: 80%; */
    border-radius: 5px;
    box-shadow: 3px 3px 10px -1px gray;

}

.pb-text-right h1 {
    font-size: 20px;
    margin-bottom: 5px;
}

.booking-name {
    font-weight: bold;
}

.pb-info-group p {
    margin-bottom: 0px !important;
}

.pb-left-col {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    flex: 0.3;
}

.pb-left-col > img {
    object-fit: cover;
    width: 10vw;
    border-radius: 50%;
    height: 10vw;
}

.pb-info-group {
    display: flex;
    align-items: center;
}

.pb-info-group p {
    margin-left: 10px;
    margin-bottom: 0px !important;
}

.pb-right-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 0.7;
}

.pb-text-container {
    display: flex;
    flex-direction: column;
}

.pb-text-container p {
    margin-bottom: 5px;
}

#venue-header {
    /* color: pink; */
    text-transform: uppercase;
    font-size: 130%;
}

.pb-button-row {
    margin-top: 10px;
    /* width: 100%; */
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    width: 60%;
    margin-bottom: 20px;
}

.pb-button-row button {
    width: 80px;
    color: white;
    padding: 8px;
    border-radius: 3px;
    font-size: 14px;
    font-weight: bold;
    transition: 300ms ease-in;
    margin-right: 20px;

}

.pb-button-row button:hover {
    cursor: pointer;
}

.pb-view-button {
    background-color: #033d73;
    border: 1px solid #033d73;
}
.pb-view-button:hover {
    background-color: white;
    color: black;
}

.pb-deny-button {
    background-color: #D94A4A;
    border: 1px solid #D94A4A;
}

.pb-deny-button:hover {
    background-color: white;
    color: #D94A4A;
}

.pb-confirm-button {
    background-color: #023e73;
    border: 1px solid #023e73;
}

.pb-confirm-button:hover {
    background-color: white;
    color: #023e73;
}

