.chat-dropdown {
    top: 80px;
    right: 0vw;
    position: absolute;
    z-index: 980;
    background-color: white;
    border-radius: 5px;
    color: white;
    border: 2px solid silver;
    border-top: none;
    padding: 2px;
    /* padding: 5px 5px 10px 10px; */
    transition: 1s ease-in;
    border-right: none;

    box-shadow: 3px 3px 10px -1px gray;

}

.chat-dropdown:last-child {
    background-color: pink !important;
}

@media all and (min-width: 1024px) and (max-width: 1280px) { }

@media all and (min-width: 768px) and (max-width: 1024px) {
    .chat-item {
        width: 30vw !important;
    }
}

@media all and (min-width: 480px) and (max-width: 768px) {
    .chat-dropdown {
        right: auto;
        width: 100vw;
    }

    .chat-item {
        width: 100% !important;
    }
 }

@media all and (max-width: 480px) {

    .chat-dropdown {
        right: auto;
        width: 100vw;
    }

    .chat-item {
        width: 100% !important;
    }

}