.instructor-credits {
    min-height: calc(100vh - 141px);
    padding: 20px;
}

.credits-container {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.credits-search {
    margin: 10px 0px;
}

.credits-search input {
    border-radius: 5px;
    font-size: 14px;
    padding: 10px 5px;
    width: 30vw;
}