.booking-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px 10px 20px 10px;
    height: max-content;
    /* box-shadow: 3px 3px 10px -1px gray; */
    /* margin-bottom: 40px; */
}
.booking-item h1 {
    margin-bottom: 5px;
    font-size: 20px;
}

.booking-item p {
    margin-bottom: 3px;
}
.hourly-item {
    display: flex;
    align-items: center;
    width: 100%;
}

.booking-item .hourly-item-left {
    flex: 0.3 !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.booking-item .hourly-item-right {
    flex: 0.7 !important;
}



.booking-item .css-i4bv87-MuiSvgIcon-root {
    height: 10vh;
    width: 10vh;
}