.confirmed-booking-item {
    display: flex;
    margin-bottom: 20px;
    /* border: 2px solid grey; */
    border: none;
    padding: 10px;
    /* width: 75%; */
    border-radius: 5px;
    box-shadow: 3px 3px 10px -1px gray;
    /* height: 30vh; */

}

.cb-left-col {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    flex: 0.3;
}

.cb-left-col > img {
    object-fit: cover;
    width: 10vw;
    border-radius: 50%;
    height: 10vw;
}

.cb-right-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 0.7;
}

.cb-text-container {
    display: flex;
    flex-direction: column;
}

.cb-text-container p {
    margin-bottom: 5px;
}

.cb-info-group {
    display: flex;
    align-items: center;
}

.cb-info-group p {
    margin-left: 20px;
    margin-bottom: 0px !important;
}

.cb-button-row {
    margin-top: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 30%;
}

.cb-button-row button {
    width: 80px !important;
    color: white;
    padding: 8px;
    border-radius: 3px;
    font-size: 14px;
    font-weight: bold;
    margin-right: 25px;
    transition: 300ms ease-in;

}

.cb-button-row button:hover {
    cursor: pointer;
}

.cb-view-button {
    background-color: black;
    border: 1px solid black;
}
.cb-view-button:hover {
    background-color: white;
    color: black;
}

.cb-deny-button {
    background-color: #D94A4A;
    border: 1px solid #D94A4A;
}

.cb-deny-button:hover {
    background-color: white;
    color: #D94A4A;
}

.pb-text-container {
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
}

.pb-text-left {

}

.cb-reschedule-button {
    background-color: #023e73;
    width: max-content !important;
    border-color: #023e73;
}

.cb-reschedule-button:hover {
    background-color: transparent !important;
    color: #023e73;
}