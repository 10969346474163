.cert-upload-modal {
    z-index: 999;
    background-color: rgba(0,0,0,0.7); /* Black w/ opacity */
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cert-upload-modal-content {
    border: 1px solid grey;
    border-radius: 3px;
    width: max-content;
    height: max-content;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: black;
    background-color: white;
}

.cert-upload-modal-content .dz-upload-container {
    margin: 30px 0px;
    padding: 50px 10px;
}

.cert-upload-modal-content input {
    border: none;
    box-shadow: 3px 3px 10px -1px gray;
    width: 100%;
}

.cu-button-row button {
    margin-top: 20px;
    background-color: black;
    color: white;
    padding: 7px 35px;
    text-transform: uppercase;
    border-radius: 3px;
    border: 1px solid black;
    cursor: pointer;
    transition: 200ms ease-in-out;
}

.cu-button-row {
    justify-content: center !important;
}