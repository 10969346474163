.add-class-modal {
    z-index: 999;
    background-color: rgba(0,0,0,0.7); /* Black w/ opacity */
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.add-class-modal-content {
    border: 1px solid grey;
    border-radius: 3px;
    width: max-content;
    height: 75vh;
    padding: 30px;
    /* display: flex; */
    overflow: scroll;
    /* flex-direction: column; */
    margin: auto;
    /* align-items: flex-start; */
    /* justify-content: space-evenly; */
    color: black;
    background-color: white; /* Black w/ opacity */
}

.add-class-modal-content h5 {
    margin-bottom: 10px;
}
.add-class-modal-content h2 {
    margin-bottom: 10px;
}

#react-select-7-input::placeholder {
    color: pink !important;
}

.add-class-modal p {
    color: black !important;
    /* opacity: 1 !important; */
}

.css-1wa3eu0-placeholder {
    /* visibility: hidden; */
}

.add-class-modal input {
    /* box-shadow: 3px 3px 10px -1px gray;
    border: none; */
}

.add-class-item {
    width: 100%;
}

.add-class-item input {
    width: 100%;
}

.item-right {
    margin-left: 20px;
}

.add-class-row .rates-input-box {
    border: none;
}

.add-class-modal .dz-upload-container {
    padding: 50px 10px !important;
}

.dz-upload-container img {
    height: 25vh;
}

.bs-input input {
    box-shadow: 3px 3px 10px -1px gray;
    border: none;
}

.bs {
    box-shadow: 3px 3px 10px -1px gray;
    border: none;
}

.add-class-modal .css-yk16xz-control {
    border: none !important;
}

.css-1g6gooi input {
    height: 30px !important;
}

.add-class-row input {
    flex: 0.5;
    padding-left: 16px;
}

.add-class-row {
    display: flex;
    align-items: center;
    width: 90%;
    margin-bottom: 10px;
    justify-content: space-between;
}

.add-class-modal textarea {
    border: none;
    resize: none;
    width: 100%;
    box-shadow: 3px 3px 10px -1px gray;
    padding-left: 16px !important;
}

/* .css-1wa3eu0-placeholder::after {
    content: 'Location' !important;
    visibility: visible;
} */

.delete-container {
    justify-content: flex-start !important;
    align-items: center;
    margin-top: 20px !important;
}

.delete-container input {
    width: 25vw;
}

.confirm-delete-button {
    margin-left: 20px;
    background-color: black;
    border-color: black;
    cursor: pointer;
    height: 40px;
    padding: 0px 10px !important;
}

.confirm-delete-button:hover {
    background-color: transparent;
    color: black;
    border: 1px solid black;

}

.confirm-delete-button:disabled {
    background-color: grey !important;
    /* border-color: grey !important; */
    border: none !important;
    cursor: inherit;
}

.add-class-modal-content .confirm-button {
    margin-left: 0px !important;
    transition: 200ms ease-in-out;
}

.confirm-delete-button:disabled:hover {
    background-color: grey !important;
    color: white !important;
}

@media all and (min-width: 1024px) and (max-width: 1280px) { }

@media all and (min-width: 768px) and (max-width: 1024px) { }

@media all and (min-width: 480px) and (max-width: 768px) { }

@media all and (max-width: 480px) {

    .add-class-row {
        flex-direction: column;
    }

    .bookings-header-row {
        flex-direction: column;
    }


 }