.price-increase {
    display: flex;
    align-items: center;
    /* box-shadow: 3px 3px 10px -1px gray; */
    /* padding: 20px; */
    /* width: 60vw; */
    /* justify-content: space-evenly; */
    margin-bottom: 20px;
}

.price-increase-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
}

.pi-percentage {
    display: flex;
    align-items: center;
    margin-right: 20px;
}

.pi-percentage input {
    width: 5vw;
    height: 55px !important;
    box-shadow: 3px 3px 10px -1px gray;
    margin-right: 10px;
}

.react-time-picker {
    /* border: 2px solid pink; */
    /* padding: 5px; */
}

.price-increase .react-time-picker__wrapper {
border: none;
height: 55px;
box-shadow: 3px 3px 10px -1px gray;
margin-right: 20px;
border: none !important;
border-radius: 5px;
}

.react-time-picker__inputGroup {
    display: flex;
    align-items: center;

}

.price-increase h3 {
    margin-right: 20px;
}

.pi-weekday {
    margin-left:  20px;
    margin-right: 20px;
}

.pi-weekday .searchWrapper {
    box-shadow: 3px 3px 10px -1px gray;
    /* margin-right: 20px; */
    /* width: 30vw; */
    width: max-content;
    max-width: 60vw;
}

.price-increase .MuiSvgIcon-root {
    cursor: pointer;
    /* transform: scale(1.3); */
}

.price-increase .MuiSvgIcon-root:active {
    transform: scale(1.4);
}

.end-date-container input {
    box-shadow: 3px 3px 10px -1px gray;
    border: none;
    padding: 10px;
}

.end-date-container .MuiCheckbox-colorSecondary.Mui-checked {
    color: grey !important
}

.end-date-container {
    display: flex;
    align-items: center;
}

.end-date-container h4 {
    margin-right: 40px;
}

.pi-end input, .pi-start input {
    border: none;
    height: 55px !important;
    box-shadow: 3px 3px 10px -1px gray;

}

.pi-end {
    margin-right: 40px;
}

.pi-start {
    margin-right: 20px;
}