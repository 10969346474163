.black-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    background-color: #033d73;
    z-index: 100;
    width: 100%;
    top: 0;
    height: 80px
}