.instructor-profile-edit-page .MuiLinearProgress-root {
    background-color: lightgray !important;
    width: 30vw;
    margin-top: 10px;
    height: 1vh;
    border-radius: 5px;
}

.instructor-profile-edit-page {
    min-height: calc(100vh - 141px);
}

.instructor-profile-edit-page .MuiLinearProgress-bar {
    background-color: green;
}

.prog-group {
    display: flex;
}

.prog-group .info-icon {
    margin-left: 40px;
    cursor: pointer;
    opacity: 0.7;
    transition: 10ms ease-in-out;
}

.prog-group .info-icon:active {
    transform: scale(2);
}

.cert-header-row {
    display: flex;
    width: 30vw;
    align-items: center;
    justify-content: space-between;
}

.cert-header-row h3 {
    margin-bottom: 0px !important;
}

.add-cert-group {
    display: flex;
    align-items: center;
    box-shadow: 3px 3px 10px -1px gray;
    padding: 10px 20px;
    border-radius: 5px;
    transition: 200ms ease-in-out;
    cursor: pointer;
}

.add-cert-group:hover {
    background-color: black;
    color: white;
}
