.stats-settings {
    display: flex;
    z-index: 600;
    flex-direction: column;
    position: absolute;
    background-color: white;
    width: max-content;
    box-shadow: 3px 3px 10px -1px gray;
    padding: 20px;
    margin: 80px 0px;
}

.stats-settings h3 {
    margin-bottom: 10px;
}

.stats-settings h1 {
    margin-bottom: 20px;
}

.metrics-choices {
    display: flex;
    flex-direction: column;
}

.metrics-choice {
    display: flex;
    align-items: center;
}

.metrics-row {
    display: flex;
    margin-bottom: 20px;
}

.metrics-button-row button {
    border: 1px solid #023e73;
    color: white;
    background-color: #023e73;
    border-radius: 5px;
    font-size: 18px;
    padding: 8px 40px 8px 40px;
    text-align: center;
}

.metrics-button-row button:hover {
    cursor: pointer;
    background-color: white;
    color: #023e73;
}

.metrics-button-row button:disabled {
    background-color: grey;
    border-color: grey;
}
.metrics-button-row button:disabled:hover {
    cursor: inherit;
    color: white;
    border-color: grey;
}

.error-msg {
    color: #D94A4A;
    margin-bottom: 20px;
    transition: 200ms ease-in-out;
}