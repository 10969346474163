.listing-complete-modal {
    z-index: 999;
    /* background-color: rgba(0,0,0,0.7);  */
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.listing-modal-content {
    width: 40vw;
    height: 30vh;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: rgba(0,0,0,0.95); /* Black w/ opacity */
}

.listing-complete-modal h1 {
    text-decoration: none;
}

.text-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}