.custom-toolbar {
justify-content: space-between !important;
display: flex;
}

.rbc-toolbar-left {
flex: 0.2;
}

.rbc-toolbar-left span {
    box-shadow: 3px 3px 10px -1px gray;
    padding: 5px 15px;
    border-radius: 5px;
    transition: 200ms ease-in-out;
    font-size: 13px !important;
    color: #373A3C;
}

.rbc-toolbar-left span:hover {
    background-color: black;
    color: white
}

.rbc-toolbar button {}

.rbc-toolbar-left span:active {
    transform: scale(2.15) !important;
    padding: 7px 21px;
    background-color: black !important;
    opacity: 1;
    color: white;
}

.rbc-toolbar-right {
/* flex: 0.2; */
border: none;
}

.rbc-btn-group {
    box-shadow: 3px 3px 10px -1px gray;

}

.rbc-toolbar-middle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
    flex: 0.3;
    /* box-shadow: 3px 3px 10px -1px gray; */
}

.prev-icon, .next-icon {
    /* transition: 250ms ease-in; */
}

.prev-icon:hover, .next-icon:hover {
    cursor: pointer;
}

.prev-icon:active, .next-icon:active {
    opacity: 0.5;
}

.selected-button {
    background-color: lightgrey !important;
}

@media all and (min-width: 1024px) and (max-width: 1280px) { }

@media all and (min-width: 768px) and (max-width: 1024px) { }

@media all and (min-width: 480px) and (max-width: 768px) { }

@media all and (max-width: 480px) {

    .custom-toolbar {
        /* justify-content: center !important; */
    }

    .rbc-toolbar-middle {
        width: auto;
    }

    .rbc-toolbar-right {
        margin-top: 10px;
    }

    .rbc-toolbar-right {
        width: 100%;
    }

}