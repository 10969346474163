.analytics-graphs {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    box-shadow: 3px 3px 10px -1px gray;
    padding: 20px;
    margin-bottom: 40px;
    border-radius: 7px;


}

.ag-header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-evenly;
}

.ag-header h1 {
    font-size: 25px;
}

.analytics-graphs .select-search {
    width: 20vw;
}

.graph-container {
    width: 90%;
    margin-top: 20px;
}

.select-search__input {
    text-transform: capitalize;
}