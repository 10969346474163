.requested-session-modal {
    z-index: 799;
    background-color: rgba(0,0,0,0.7); /* Black w/ opacity */
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.requested-session-modal-content {
    box-shadow: 3px 3px 10px -1px grey;
    border-radius: 3px;
    width: 50vw;
    height: max-content;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    color: black;
    background-color: white; /* Black w/ opacity */
}

.requested-session-modal-content h1 {
    font-size: 24px;
    margin-bottom: 5px;
}

.rsm-group {
    display: flex;
    align-items: center;
}

.rsm-button-row {
    justify-content: flex-end !important;
}

.rsm-button-row .confirm-button {
    margin-left: 20px;
}