.client-relationships {
    display: flex;
    flex-direction: column;
    height: max-content;
    font-weight: bold;
}

.new-client {
    border-radius: 5px;
    display: flex;
    align-items: center;
    width: max-content;
    padding: 20px 30px;
    box-shadow: 3px 3px 10px -1px gray;
    transition: 200ms ease-in-out;
    cursor: pointer;
}

.add-client-icon {
    margin-right: 10px;
}

.new-client:hover {
    color: white !important;
    background-color: #033d73 !important;
}

.new-client:active {
    transform: scale(1.03);
}


.clients-header {
    /* padding: 20px; */
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.back-button-row {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.back-button-row p {
    margin-left: 10px;
}

.client-thumbs {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 40px;
}

.clients-header h2 {
    /* text-decoration: underline; */
    font-size: 32px;
}

.clients-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.no-clients  {
    margin-top: 50px;
    display: flex;
    align-items: center;
    flex-direction: column;
    /* justify-content: center; */
    height: 50vh;
    /* font-size: 25px; */
}

.selected-client {
    margin-top: 50px;
    border: 1px solid lightgray;
    box-shadow: 1px 4px 2px -2px gray;

    padding: 20px;
}

.selected-client-header {
    display: flex;
    width: 100%;
    align-items: center;
    /* justify-content: space-evenly; */
}

.selected-client-header img {
    height: 150px;
    width: auto;
    border-radius: 50%;
    margin-right: 100px;
}

.selected-client-header h3 {
    font-size: 30px;
    margin-bottom: 5px;
}

.selected-client-header span {
    opacity: 0.5;
    margin-top: 5px;
}

.selected-client-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
}

.client-content-top {
    flex: 0.3;
    display: flex;
    /* flex-direction: column; */
    /* align-items: center; */
    justify-content: center;
    width: 100%;
}

.top-content-left {
    padding: 20px;
    flex: 0.5;
    display: flex;
    flex-direction: column;
}

.top-content-right {
    flex: 0.5;
}

.client-content-bottom {
    font-weight: bold;
    flex: 0.7;
    padding: 20px;
}

.client-info-row {
    margin-bottom: 10px;
display: flex;
justify-content: space-between;
}

.client-info-row p {
    /* margin-left: 20px; */
}

.client-content-divider {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
}

.client-content-divider hr {
    width: 90%;
}

.client-card-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 75px;
}

.bar-chart-header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
}

.bar-chart-header h2 {
    text-decoration: none;
}

.selected-client-menu {
    display: flex;
}

.selected-client-menu button {

    border: none;
    font-size: 16px;
    padding: 10px 10px 10px 10px;
    box-shadow: inset 0 0 0 0 #fff;
	-webkit-transition: all ease 0.8s;
	-moz-transition: all ease 0.8s;
	transition: all ease 0.8s;
}

.selected-client-menu button:hover {
    cursor: pointer;
    background-color: rgb(219, 219, 219);
}

.active-client-menu-button {
    box-shadow: inset 100px 0 0 0 #000000;

    background-color: #033d73;
    color: white;
}

.active-client-menu-button:hover {
    background-color: #033d73 !important;
}

.client-relationships .select-search__select {
    /* top: 60px !important; */
}
