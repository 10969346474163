.home-summary {
    box-shadow: 3px 3px 10px -1px gray;
    height: 23vh;
    width: max-content;
    padding: 10px;
    border-radius: 0px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    color: black;
}

.vr {
    border-left: 2px solid black;
    height: 80%;
    margin-left: 20px;
    margin-right: 20px;
}

.bookings-item {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-right: 30px;
}

.bookings-item > h3 {
    font-size: 22px;
}

.bookings-item > p {
    font-size: 16px;
}

.total-bookings {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.total-bookings > h3 {
    margin-right: 10px;
    flex:none;
    /* background-color: wheat; */
    font-size: 40px;
}

.total-bookings > p {
    flex:none;
    /* background-color: wheat; */
    font-size: 40px;
}