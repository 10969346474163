.instructor-available-modal {
    z-index: 999;
    background-color: rgba(0,0,0,0.7); /* Black w/ opacity */
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    padding: 20px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.instructor-available-modal-content {
    box-shadow: 3px 3px 10px -1px black;
    border-radius: 3px;
    width: max-content;
    height: max-content;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    color: black;
    width: 70vw;
    background-color: white; /* Black w/ opacity */
}

.instructor-available-modal-content input {
    border: none;
    box-shadow: 3px 3px 10px -1px gray;
}

.selector-container {
    margin: 20px 0px;
}

.instructor-available-modal-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* width: 50%; */
    margin-bottom: 10px;
}

.sched-msg {
    width: 100%;
}

.sched-msg p {
    font-size: 12px !important;
    width: 100% !important;
    margin-bottom: 20px !important;
}

.instructor-available-modal #search_input {
    height: auto !important;
}

.instructor-available-modal .chip {
    margin-top: 0px !important;
}

.instructor-available-modal-row .searchWrapper {
    border: none !important;
    padding: 0px 5px !important;
    max-width: 50vw;
    align-items: center !important;
}

.instructor-available-modal-row .searchBox {
    width: 200px !important;
    box-shadow: none;
}

.instructor-available-modal-row .optionListContainer input {
    width: auto !important;
    box-shadow: none;
}

.instructor-available-modal-row h1 {
    margin: 0px 40px;
}

.instructor-available-modal-content .button-row {
    justify-content: flex-end !important;
}

.instructor-available-modal-content .cancel-buttton {
    background-color: black !important;
    color: white !important;
    border-color: black !important;
}

.instructor-available-modal-content .cancel-buttton:hover {
    background-color: transparent !important;
    color: black !important;
}

.instructor-available-modal-content .confirm-button {
    margin-right: 20px;
}

.instructor-available-modal-content .confirm-button:hover {
    background-color: transparent !important;
}

.instructor-available-modal p {
    font-size: 18px;
    margin-right: 50px;
    width: 50px;

}

.availability-error-message-row p {
    color: #D94A4A;
}

.middle-pee {
    flex: 1;
}

.bold {
    font-weight: bold;
    flex: 0.2;
}

.switch-container {
    display: flex;
    width: 200px;
    justify-content: space-between;
}

.switch-container p {
    margin-right: 0px !important;
    width: auto !important;
}

.rmdp-day.rmdp-today span {
    background-color: #023e73 !important;
    color: white !important
}

.rmdp-week-day {
    color: #023e73 !important;
}

.rmdp-arrow {
    border-color: #023e73 !important;
}

.sd-pick .rmdp-header {
    display: none;
}

.sd-pick .rmdp-day-picker > div:first-child {
    /* display: none; */
}

.instructor-available-modal .error-message-row {
    display: flex;
    width: 100%
}

.instructor-available-modal .error-message-row p {
    width: auto !important;
    margin: 0px;
}



.rmdp-arrow-container:hover {
    background-color: #023e73 !important;
}

.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
    background-color: black !important;
}

.rmdp-day.rmdp-selected span:not(.highlight) {
    background-color: black !important;
}

.instructor-available-dt-picker {
    /* background-color: white !important; */
    /* width: 100px */
    /* font-size: 18px; */
}

.instructor-available-dt-picker input p {
    color: white !important;

}

.react-datetime-picker {
    color: black;
}

input.react-datetime-picker__inputGroup__input.react-datetime-picker__inputGroup__day {
    color: white
}

input.react-datetime-picker__inputGroup__input.react-datetime-picker__inputGroup__day {
    color: white !important;
}

.react-datetime-picker__inputGroup__input {
    color: white;
}

.react-datetime-picker__button {
    color: white !important;
}

.react-datetime-picker__inputGroup__divider {
    color: white;
}
.react-datetime-picker *:after {
    color: white;
}

.react-datetime-picker__inputGroup__leadingZero {
    color: white !important;
}

.react-datetime-picker__button__icon {
    color: white !important;
}

.instructor-available-options-container {
    display: flex;
    width: 100%;
    flex-direction: column;

}

.instructor-available-options-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.instructor-available-options-group input {
    background-color: white;
    color: black;
    padding: 5px;
    width: 100px;
}

.instructor-available-options-group p {
    margin: 0;
}

.instructor-available-modal-content .react-time-picker__wrapper {
    background-color: white !important;
    width: 200px !important;
}

.instructor-available-modal-content .date-row input {
    width: 200px !important;
}

@media all and (min-width: 1024px) and (max-width: 1280px) { }

@media all and (min-width: 768px) and (max-width: 1024px) { }

@media all and (min-width: 480px) and (max-width: 768px) {

    .instructor-available-modal-content {
        flex-direction: column !important;
        height: max-content;
        width: 90vw;
    }

    .instructor-available-modal-content h1 {
        font-size: 20px;
        text-align: center;
    }

    .instructor-available-modal-row {
        flex-direction: column;
    }

    .instructor-available-modal-row p {
        margin-right: 0px;
        margin: 20px 0px;
    }

    .instructor-available-options-container {
        margin: 30px 0px;
        align-items: center;
    }

    .instructor-available-options-group {
        width: 100%;
        justify-content: space-between;
    }

    .instructor-available-options-header {
    width: 70%;
    }

    .instructor-available-modal-content .button-row {
        justify-content: space-evenly !important;
    }


 }

@media all and (max-width: 480px) {
.instructor-available-modal-content {
    flex-direction: column !important;
    height: max-content;
    width: 90vw;
}

.instructor-available-modal-content h1 {
    font-size: 20px;
    text-align: center;
}

.instructor-available-modal-row {
    flex-direction: column;
}

.instructor-available-modal-row p {
    margin-right: 0px;
    margin: 20px 0px;
}

.instructor-available-options-container {
    margin: 30px 0px;
    align-items: center;
}

.instructor-available-options-group {
    width: 100%;
    justify-content: space-between;
}

.instructor-available-options-header {
width: 70%;
}

.instructor-available-modal-content .button-row {
    justify-content: space-evenly !important;
}

}