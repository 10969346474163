.home {
    display: flex;
    padding-top: 50px;
    /* padding-left: 50px; */
    padding-bottom: 50px;
    align-items: center;
    background-color: #fff;
    /* background-image: url('./gym.jpg'); */
    flex-wrap: wrap;
    justify-content: space-around;
    flex-direction: column;
    min-height: calc(100vh - 141px);
}

.home-top {
    display: flex;
    align-items: center;
    width: 90%;
    justify-content: space-between;
}

.home-top-left {
    flex: 0.45;
    height: 100%;
}

.home-top-right {
    flex: 0.45;
    height: 100%;

}

.home-bottom {
    width: 90%;
    margin-top: 40px;
}

.home-bottom .info-card {
    width: 23vw;
}


.home-left {
    flex: 0.4;
    display: flex;
    flex-direction: column;
    width: 30vw;
}

.home-right {
    display: flex;
    flex: 0.5;
    justify-content: space-between;
    width: 100%;
    padding: 20px;

}

.news-feed-container {
    background-color: transparent;
    /* flex: 0.2; */
    /* height: 30vh; */
}

.summaries-container {

    /* background-color: tomato; */
    align-items: center;
    flex: 0.67;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.summary-container {
    /* background-color: #fafafa; */
    color: white;
    margin-top: -20px;
    height: 30vh;
    /* background-color: wheat; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.thirty-day-container {
    /* background-color: black; */

    border: 2px solid gray;
    height: max-content;
    width: 80%;
    border-radius: 10px;
    /* overflow: scroll; */
}

.home-bookings-container {
    width: 100%;
    height: 100%;
    /* height: 50vh; */
    /* overflow: scroll; */
    /* padding: 20px; */
    border-radius: 5px;
    /* border: 1px solid grey; */
    box-shadow: 3px 3px 10px -1px gray;

}

.stats-settings {
    max-width: 50vw;
}

.metrics-row {
    flex-wrap: wrap;
}

@media all and (min-width: 1024px) and (max-width: 1280px) {
    .info-card {
        width: auto;
        height: 25vh !important;
    }
}

@media all and (min-width: 768px) and (max-width: 1024px) {

    .metrics-row {
        flex-wrap: wrap;
    }
    .home {
        /* flex-direction: column-reverse; */
    }

    .home-left {
        width: 90vw;
    }

    .stats-box {
        width: 90vw !important;
    }

    .home-right {
        align-items: center;
        justify-content: center;
    }

    .stats-box-container {
        width: 100%;
    }

    .info-card {
        width: auto;
        height: 20vh !important;
    }

    .info-card-content-container p {
        width: 60% !important;
    }

    .stats-box-row {
        /* flex-direction: column; */
    }

    .home .info-card {
        width: 30%;
    }

    .stats-box-row {
        width: 100%;
    }
 }



@media all and (min-width: 480px) and (max-width: 768px) {

    .metrics-row {
        flex-wrap: wrap;
    }

    .stats-settings {
        width: 90vw !important;
    }

    .home {
        flex-direction: column;
    }

    .home-left {
        width: 90vw;
    }

    .info-card {
        width: 75vw !important;
    }

    .stats-box-container {
        width: 100%;
    }
    .stats-box-row {
        flex-direction: column;
    }

    .home .info-card {
        /* width: 100%; */
    }

    .home-top {
        flex-direction: column;
    }

    .home-top-left, .home-top-right {
        width: 100%;
    }

    .home-top-right {
        margin-top: 40px !important;
    }
 }

@media all and (max-width: 480px) {

    .metrics-row {
        flex-wrap: wrap;
    }

    .stats-settings {
        width: 90vw !important;
    }

    .home {
        flex-direction: column;
    }

    .home-left {
        width: 90vw;
    }

    .stats-box-container {
        width: 100%;
    }
    .stats-box-row {
        flex-direction: column;
    }

    .home .info-card {
        width: 100%;
    }

    .home-top {
        flex-direction: column;
    }

    .home-top-left, .home-top-right {
        width: 100%;
    }

    .home-top-right {
        margin-top: 40px !important;
    }
}


