.listings {
    display: flex;
    flex-direction: column;
    height: max-content;
    min-height: calc(100vh - 144px);
}


.listings-container h4 {
    margin-left: 50px;
    opacity: 0.7;
}

.new-listing:hover {
    cursor: pointer;
    /* transform: scale(1.02); */
    transition: all 0.2s;
    background-color: #50A7F2;
    color: white;
}

.new-listing {
    /* border: 1px solid black; */
    border-radius: 5px;
    display: flex;
    align-items: center;
    width: max-content;
    padding: 20px 30px;
    margin-left: 50px;
    margin-top: 50px;
    box-shadow: 3px 3px 10px -1px gray;
    color: #50A7F2;
    margin-bottom: 50px;
}

.new-listing h1 {
    font-size: 20px !important;
    margin: 0px !important;
    margin-left: 20px !important;
    text-decoration: none !important;
}

.add-icon {
    transform: scale(1.5);
}

.listings-container {
    margin-bottom: 50px;
    width: 100%;
    max-width: 100vw;
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    /* padding-left: 2%; */
    /* padding-right: 2%; */
    flex-wrap: wrap;
}


.next-button {
    /* margin-top: 30px; */
    font-weight: bold;
    border: 1px solid #023e73;
    color: white;
    background-color: #023e73;
    border-radius: 5px;
    font-size: 20px;
    padding: 10px 50px 10px 50px;
    min-width: 155px;
    text-align: center;
    margin-left: 20px;
    margin-top: 0px;
    margin-bottom: 100px;
    align-self: flex-end !important;
    transition-duration: 300ms;
    transition-timing-function: ease-in;
}

.next-button:hover {
    cursor: pointer;
    background-color: white;
    color: #023e73;
    border: 1px solid #023e73;
}

.next-button:active {
    background-color: lightgray;
}

.next-button:disabled {
    background-color: grey;
    border: 1px solid grey;
}

.next-button:disabled:hover {
    cursor: auto;
    color: white;
}
.back-button:disabled {
    background-color: grey !important;
    border: 1px solid grey !important;
}

.back-button:disabled:hover {
    cursor: auto;
    color: white;
}





.back-button {
    /* margin-top: 30px; */
    font-weight: bold;
    border: 1px solid #D94A4A;
    color: white;
    background-color: #D94A4A;
    border-radius: 5px;
    font-size: 20px;
    padding: 10px 50px 10px 50px;
    width: 155px;
    text-align: center;
    margin-left: 20px;
    margin-top: 0px;
    margin-bottom: 100px;
    transition-duration: 300ms;
    transition-timing-function: ease-in;
}

.back-button:hover {
    cursor: pointer;
    background-color: white;
    color: #D94A4A;
    border: 1px solid #D94A4A;
}

.back-button:active {
    background-color: lightgray;
}

.step-button-row {
    display: flex;
    justify-content: space-between;
    width: 80%;
}

.start-button-row {
    display: flex;
    justify-content: flex-end;
    width: 80%;
}

.special-button-row {
    display: flex;
    justify-content: space-between;
    width: 60%;
}

#current-listings-header {
margin-left: 50px;
font-size: 30px !important;
text-decoration: none;
}


.listing-error-row {
margin-bottom: 20px;
}

.listing-error-row p {
    color: #D94A4A;
    padding-left: 20px;
    font-weight: bold;
    filter: drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.2));
}

.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
    height: 75px !important;
}

.top-row {
    display: flex;
    /* align-items: center;
    justify-content: space-between; */
    width: 100%;
}

.middle-row {
    display: flex;
    align-items: center;
    justify-content: flex-start !important;
    width: 100%;

}
.bottom-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

}


@media all and (min-width: 1024px) and (max-width: 1280px) { }

@media all and (min-width: 768px) and (max-width: 1024px) {
    .image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
        height: 60px !important;
    }
}

@media all and (min-width: 480px) and (max-width: 768px) {
    .listing {
        align-items: center;
        margin-left: 0px !important;
        height: max-content !important;
        align-items: center !important;
    }

    .listings {
        align-items: center;
    }

    .listing-title {
        padding-left: 0px !important;
    }

    .listing-images {
        margin-bottom: 20px;
    }

    .listing-details {
        flex-direction: column;

    }

    .listing-button-row {
        width: 90%;
    }

    .listing-button-row button {
        margin: 0px !important;
    }

    #current-listings-header {
        margin: 0px;
        text-align: center;
    }

    .listing-description {
        align-items: center !important;
    }

    .listing-description h2 {
        width: 100%;
    }

    .listings-container {
        flex-direction: column;
        align-items: center;
    }

    .listing {
        width: 90vw !important;
    }

    .description-container {
        width: 100%;
    }
    .new-listing {
        margin-left: 0px !important;

    }

    .price-increase {
        flex-direction: column;
        width: 90vw !important;
    }

    .price-increase {
        flex-direction: column;
    }

    .price-increase h3 {
        margin: 0px !important;
    }

    .pi-start {
        margin: 0px !important;
    }

    .pi-start input {
        width: 100%;
    }

    .middle-row {
        margin: 10px 0px;
        justify-content: space-evenly !important;
    }

    .bottom-row {
        justify-content: space-evenly !important;
    }

    .pi-weekday .searchWrapper {
        width: 100% !important;
    }

    .pi-percentage {
        margin-right: 0px !important;
    }

    .pi-percentage input {
        width: 20vw !important;
    }

    .pi-end {
        margin-right: 0px !important;
    }

    .pi-weekday {
        margin-left: 0px !important;
    }

    .pi-end input, .pi-start input {
        width: 30vw;
    }

    .top-row {
        justify-content: space-between;
    }

    .middle-row {
        justify-content: space-between !important;
    }
    .bottom-row {
        justify-content: space-between !important;
    }

}

@media all and (max-width: 480px) {

    .new-listing {
        margin-left: 0px !important;

    }

    .listings {
        align-items: center;
    }

    .listing {
        align-items: center;
        margin-left: 0px !important;
        height: max-content !important;
        align-items: center !important;
    }

    .listing-title {
        padding-left: 0px !important;
    }

    .listing-images {
        margin-bottom: 20px;
    }

    .listing-details {
        flex-direction: column;

    }

    .listing-button-row {
        width: 90%;
    }

    .listing-button-row button {
        margin: 0px !important;
    }

    #current-listings-header {
        margin: 0px;
        text-align: center;
    }

    .listing-description {
        align-items: center !important;
    }

    .listing-description h2 {
        width: 100%;
    }

    .listings-container {
        flex-direction: column;
        align-items: center;
    }

    .listing {
        width: 90vw !important;
    }

    .description-container {
        width: 100%;
    }

    .price-increase {
        flex-direction: column;
    }

    .price-increase h3 {
        margin: 0px !important;
    }

    .pi-start {
        margin: 0px !important;
    }

    .pi-start input {
        width: 100%;
    }

    .middle-row {
        margin: 10px 0px;
        justify-content: space-between !important;
    }

    .bottom-row {
        justify-content: space-between !important;
    }

    .pi-weekday .searchWrapper {
        width: 100% !important;
    }

    .pi-percentage {
        margin-right: 0px !important;
    }

    .pi-percentage input {
        width: 30vw !important;

    }

    .pi-end {
        margin-right: 0px !important;
    }

    .pi-weekday {
        margin-top: 10px;
        margin-left: 0px !important;
    }

    .top-row {
        flex-direction: column;
    }

    .end-date-container {
        width: 90vw;
    }

    .pi-end input, .pi-start input {
        width: 30vw;
    }
}