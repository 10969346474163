.login-form {
    background-color: #033d73;
    height: max-content;
    width: 35vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    box-shadow: 1px 7px 10px #033d73;
}

.login-form img {
    margin-top: 10px;
    height: 7vh;
    width: auto;
    object-fit: contain;
}

.login-input-container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: max-content;
    margin-top: 40px;
}

.input-group {
    width: 80%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.input-group p {
    margin-bottom: 10px;
    color: white;
    font-size: 18px;
}

.input-group input {
    height: 5vh;
    padding-left: 5px;
    font-size: 15px;
}

.input-group input::placeholder {
    margin-right: 10px;
}

.register-action-container {
    background-color: white;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 10px;
}

.register-action-container span {
    color: #023e73;
    text-decoration: underline;
}

.register-action-container span:hover {
    cursor: pointer;
    color: black;
}

.login-button-row button {
    font-weight: bold;
    color: rgb(207, 204, 204);
    border: none;
    background-color: #D94A4A;
    border-radius: 2px;
    font-size: 20px;
    padding: 7px 30px 7px 30px;
    border: 1px solid #D94A4A;
    margin-bottom: 20px;
    margin-top: 20px;
    transition: 200ms ease-in-out;
}

.login-button-row button:hover {
    cursor: pointer;
    color: #D94A4A;
    background-color: black;
}

.login-button-row button:active {
    background-color: white;
}

.forgot-password {
    color: white;
    margin-bottom: 20px;
    cursor: pointer;
    text-decoration: underline;
    transition: 200ms ease-in-out;
}

.forgot-password:hover {
    color: #dddddd;
}