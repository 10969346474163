.instructor-facility-review-modal {
    z-index: 799;
    background-color: rgba(0,0,0,0.7); /* Black w/ opacity */
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 300ms ease-in-out;

}

.instructor-facility-review-modal-content {
    box-shadow: 3px 3px 10px -1px grey;
    border-radius: 3px;
    width: 50vw;
    height: max-content;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    color: black;
    background-color: white; /* Black w/ opacity */
    transition: 300ms ease-in-out;
}

.instructor-facility-review-modal-content textarea {
    border:none;
    box-shadow: 3px 3px 10px -1px gray;
    resize: none;
    width: 90%;
    margin-top: 20px;
}

.stars-container {
    margin-top: 10px;
}