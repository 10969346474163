.credit-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* cursor: pointer; */
    box-shadow: 3px 3px 10px -1px grey;
    padding: 20px 10px;
    transition: 100ms ease-in-out;
    width: 32%;
    margin-bottom: 40px;
}

.credit-box:hover {
    transform: scale(1.03);
}

.cb-text {
    margin-top: 20px;
}

.credits-button-row {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.credits-button-row button {
    cursor: pointer;
}