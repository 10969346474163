.credits-modal {
    z-index: 999;
    background-color: rgba(0,0,0,0.7); /* Black w/ opacity */
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.facility-profile .credits-modal {
    align-items: flex-start;
    padding-top: 20%;
    z-index: 50;
}

.general-button {
    background-color: black;
    border-color: black;
    cursor: pointer;
}

.credits-modal-content {
    border: 1px solid grey;
    border-radius: 3px;
    width: 60vw;
    height: max-content;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    color: black;
    background-color: white; /* Black w/ opacity */
}

.select-search__select {
    text-transform: capitalize;
}

.yoco-container {
    width: 100%;
    justify-content: center;
    align-items: center;
}

.discounts-container {
    width: 100%;
}

.select-search {
    text-transform: capitalize !important;

}

.select-search__select {
    text-transform: capitalize !important;
}

.credits-modal-content h1 {
    margin-bottom: 20px;
}
.credits-modal-content h2 {
    margin-bottom: 20px;
    margin-top: 10px;
}

.credits-modal-content .button-row {
    margin-top: 20px;
}