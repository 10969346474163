.image-description {
    display: flex;
    align-items: center;

}

.image-description img {
    object-fit: contain;
    height: 100px;
    width: 80px;
    margin-right: 10px;
}

.loading-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 80px;
    width: 12vw;
}

.loading-container > input {
    padding: 5px;
}



#text-field {
    font-size: 12px;
    padding: 10px;
    width: 250px;
    height: 10px;
    outline-width: 0;
}

.right-col .MuiSvgIcon-root {
    cursor: pointer;
    color: grey;
}

.image-description button {
    margin-left: 10px;
    border-radius: 3px;
    background-color: white;
    /* border-color: #dddddd; */
    border: none;
    box-shadow: 3px 3px 10px -1px gray;
    padding: 5px;
    border-radius: 10px;
}

.image-description .MuiSvgIcon-root {
    /* fill: lightgray; */
}

.image-description button:hover {
    cursor: pointer;
    background-color: #dddddd33;
}