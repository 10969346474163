.profile-summary {
    box-shadow: 3px 3px 10px -1px gray;
    padding: 20px;
    width: max-content;
    height: max-content;
    min-width: 20vw;
}

.bookings-summary-row {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.profile-summary hr {
    width: 95%;
    margin-bottom: 20px;
    margin-top: 10px;
}

.bookings-hr-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

@media all and (min-width: 1280px) {
    .profile-summary {
        min-width: 30vw !important;
    }
}

@media all and (min-width: 1024px) and (max-width: 1280px) {
    .profile-summary {
        min-width: 30vw !important;
    }
 }

@media all and (min-width: 768px) and (max-width: 1024px) {
    .profile-summary {
        min-width: 35vw !important;
    }
 }

@media all and (min-width: 480px) and (max-width: 768px) {

    .profile-summary {
        width: 80vw;
        margin-bottom: 40px;
    }
}

@media all and (max-width: 480px) {

    .profile-summary {
        width: 80vw;
    }

}
