.bookings-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.bookings-container h2 {
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: center;
}

.booking-scroll {
    height: 40vh;
    overflow: scroll;
    padding: 0px 20px;
    width: 100%;
}

.bookings-container h3 {
    cursor: pointer;
    transition: 200ms ease-in-out;
}

  ::-webkit-scrollbar-thumb {
      color: grey !important;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }

.bookings-container h3:hover {
    opacity: 0.7;
}

.bookings-container h3:active {
    transform: scale(1.05);
}

