.review-item {
    display: flex;
    flex-direction: column;
    /* width: 100%; */
    margin-top: 40px;
    /* border: 1px solid grey; */
    border-radius: 5px;
    padding: 20px;
    box-shadow: 3px 3px 10px -1px gray;
}

.date-container {
    margin-top: 10px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    opacity: 0.7;
}

.review-text {
    width: 100%;
}

.review-content {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
}

.review-content p {
    margin-top: 5px;
}

.review-content img {
    height: 120px;
    width: auto;
    margin-right: 40px;
    border-radius: 50%;
}

.review-button-row {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
}

.review-reply-button {
    border: 1px solid #023e73;
    color: white;
    background-color: #023e73;
    border-radius: 5px;
    font-size: 18px;
    padding: 8px 40px 8px 40px;
    text-align: center;
    margin-left: 20px;
}

.review-reply-button:hover {
    cursor: pointer;
    background-color: white;
    color: #023e73;
}

.review-report-button {
    border: 1px solid #D94A4A;
    color: white;
    background-color: #D94A4A;
    border-radius: 5px;
    font-size: 18px;
    padding: 8px 40px 8px 40px;
    text-align: center;
    margin-left: 20px;
}

.review-report-button:hover {
    cursor: pointer;
    background-color: white;
    color: #D94A4A;
}
