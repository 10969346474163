.feed-item {
    display: flex;
    align-items: center;
    /* height: max-content; */
    height: 90px !important;
}

.feed-item:hover {
    cursor: pointer;
    background-color: grey;
    color: black;
}

.feed-info-container {
    padding: 5px 0 5px 0;
    /* background-color: wheat; */
    display: flex;
    flex-direction: column;
    flex: 0.8;
}

.feed-info-container > h1 {
    font-weight: normal;
    text-transform: uppercase;
    color: white;
    opacity: 0.8;
    font-size: 16px;
    letter-spacing: 0px;
    margin-bottom: 15px;
    margin-top: 5px;
}

.feed-info {
    margin-top: -10px;
    margin-bottom: 10px;
    align-items: center;
    justify-content: space-between;
    display: flex;

}

.feed-info > h2 {
    font-size: 12px;
    font-weight: normal;
    /* flex: 0.65; */
    color: white;

}

.feed-info > p {
    /* flex: 0.35; */
    font-size: 10px;
}

.feed-item-icon {
    transform: scale(1.5);
    padding-left: 10px;
    padding-right: 10px;
    opacity: 0.7;
    flex: 0.1;
    color: white;
}

.feed-divider {
    width: 90%;
    height: 1px;
    background-color: grey;
}