.message-box {
    display: flex;
    flex-direction: column;
}

.message-box-sent {
    align-items: flex-end !important;
}

.message-box-sent h4 {
    opacity: 0.5;
    font-weight: 100;
    margin-right: 60px;
}

.message-box-received h4 {
    opacity: 0.5;
    font-weight: 100;
    margin-left: 60px;
}

.message-box .MuiSvgIcon-root {
    margin-left: 5px;
}

.message {
    display: flex;
    align-items: center;
    /* width: min-content; */
    border-radius: 10px;
    padding: 10px;
    margin-top: 20px;
    /* width: max-content; */
}


.sent {
    flex-direction: row-reverse;
    /* background: lightgrey; */
    align-self: flex-end;
}

.received {
    /* background: black; */
}

.sent p {
    border-radius: 10px;
    color: black;
    background: lightgrey;
    padding: 15px 30px 15px 15px;
    /* align-self: flex-end; */
}
.received p {
    background: #033d73;
    padding: 15px 30px 15px 15px;
    color: white;
    border-radius: 10px;
}

.message > img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 2px 5px;
  }


